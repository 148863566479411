import shuffle from 'lodash/shuffle';

import {
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsTTSLabeledRenderSettings,
  type ModelsTTSRenderSettings,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import { type VoiceOver, type VoiceOverRenderDescription } from '@lp-lib/game';

import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { hasLLMCodeFences, hasVariables } from './VariableRegistry';
import type { VoiceOverRegistryPlan } from './VoiceOverRegistryPlan';

export class VoiceOverUtils {
  static HasAtLeastOneConfig(voiceOver: VoiceOver | null | undefined): boolean {
    // TODO(falcon): in fact we might want it to be the case that if there is no fallback, we return false.
    // a runtime without a fallback has no way to play voice over in case of a failure...
    return Boolean(voiceOver && (voiceOver.runtime || voiceOver.fallback));
  }

  static AsTTSRenderRequest(
    renderDescription: VoiceOverRenderDescription | null | undefined
  ): DtoTTSRenderRequest | null | undefined {
    if (!renderDescription) {
      return renderDescription;
    }

    return {
      script: renderDescription.script,
      ttsRenderSettings: {
        generatorId: renderDescription.generatorId,
        generatorSettings: {
          [renderDescription.generatorId]: {
            voiceId: renderDescription.voiceId,
            ...(renderDescription.settings
              ? uncheckedIndexAccess_UNSAFE(renderDescription.settings)[
                  renderDescription.generatorId
                ]
              : {}),
          },
        },
      },
    };
  }

  static AsTTSSettings(
    renderDescription: VoiceOverRenderDescription | null | undefined
  ): ModelsTTSRenderSettings | null | undefined {
    if (!renderDescription) return;
    return {
      generatorId: renderDescription.generatorId,
      generatorSettings: {
        elevenLabs: {
          ...renderDescription.settings?.elevenLabs,
          voiceId: renderDescription.voiceId,
        },
      },
    };
  }

  static SamplePreferredFallbackScripts(
    scripts: ModelsTTSScript[] | null | undefined
  ) {
    const shuffled = shuffle(scripts ?? []);
    const preferred = shuffled.find(
      (s) => hasVariables(s.script) || hasLLMCodeFences(s.script)
    );
    const fallback = shuffled.find(
      (s) => !hasVariables(s.script) && !hasLLMCodeFences(s.script)
    );
    return [preferred, fallback];
  }

  static BuildRuntimeFallbackVoiceOverPlansFromLegacy(
    // optionally, the old models.
    voiceOver: VoiceOver | null | undefined,
    // optionally, the new preferred script.
    preferredScript: ModelsTTSScript | null | undefined,
    // optionally, the new fallback script.
    fallbackScript: ModelsTTSScript | null | undefined,
    ttsOptions: ModelsTTSLabeledRenderSettings[] | null | undefined,
    aiHostVoiceId: string | undefined | null,
    tags: string[] = []
  ) {
    if (voiceOver && VoiceOverUtils.HasAtLeastOneConfig(voiceOver)) {
      // we have some legacy configuration, so we'll use that and try to maintain the old semantics.
      const runtime = VoiceOverUtils.AsTTSRenderRequest(voiceOver.runtime);
      const fallback = VoiceOverUtils.AsTTSRenderRequest(
        voiceOver.fallback?.renderDescription
      );

      return this.BuildRuntimeFallbackVoiceOverPlans(
        runtime,
        fallback,
        aiHostVoiceId,
        tags
      );
    }

    // we have no legacy voiceover settings, so we're going to assume we're in the "new world". here we expect some
    // tts options or an aiHostVoiceId, otherwise we cannot render.
    const ttsRenderSettings = ttsOptions?.[0];
    if (
      (!aiHostVoiceId && !ttsRenderSettings) ||
      (!preferredScript && !fallbackScript)
    )
      return [];

    // the block has explicit scripts it wants to use.
    const runtime = preferredScript
      ? { script: preferredScript.script, aiHostVoiceId, ttsRenderSettings }
      : undefined;

    const fallback = fallbackScript
      ? { script: fallbackScript.script, aiHostVoiceId, ttsRenderSettings }
      : undefined;

    return this.BuildRuntimeFallbackVoiceOverPlans(
      runtime,
      fallback,
      aiHostVoiceId,
      tags
    );
  }

  static BuildRuntimeFallbackVoiceOverPlans(
    runtime: Nullable<DtoTTSRenderRequest>,
    fallback: Nullable<DtoTTSRenderRequest>,
    aiHostVoiceId: string | undefined | null,
    tags: string[] = []
  ) {
    const plan: VoiceOverRegistryPlan = { entries: [] };

    // priority 1: runtime script, likely with variables
    if (runtime) {
      plan.entries.push({
        ...runtime,
        script: runtime.script,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId
          ? undefined
          : runtime.ttsRenderSettings,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      });
    }

    // Priority N: The rest are all a form of fallback.

    if (fallback) {
      // priority 2: handcrafted fallback description, rendered at game start,
      // if there are aivoicesettings/block settings/renderdesc settings
      plan.entries.push({
        ...fallback,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId
          ? undefined
          : fallback.ttsRenderSettings,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlLongLive,
      });
    }
    return plan.entries.length === 0 ? [] : [{ plan, tags }];
  }
}
