import shuffle from 'lodash/shuffle';
import React, { type ReactNode, useContext, useMemo } from 'react';
import { proxy } from 'valtio';

import {
  EnumsH2HJudgingUserGroup,
  EnumsMediaType,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import { assertExhaustive, type HeadToHeadBlock } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { type Participant, type TeamV0 } from '../../../../types';
import { err2s } from '../../../../utils/common';
import { TagQuery } from '../../../../utils/TagQuery';
import {
  markSnapshottable,
  useSnapshot,
  ValtioUtils,
} from '../../../../utils/valtio';
import { useClock } from '../../../Clock';
import { useEmojisAnimation } from '../../../EmojiBoard/EmojiAnimation';
import { type FirebaseService, useFirebaseContext } from '../../../Firebase';
import {
  useLastJoinedParticipantByUserId,
  useMyInstance,
  useParticipantsAsArray,
  useParticipantsAsArrayGetter,
} from '../../../Player';
import { useTeamMembers, useTeamsGetter } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue';
import {
  LVOBroadcastPlayer,
  lvoCacheWarm,
  LVOLocalPlayer,
  lvoResolveTTSRequest,
  lvoTTSRequestFromPlan,
} from '../../../VoiceOver/LocalizedVoiceOvers';
import { VoiceOverUtils } from '../../../VoiceOver/utils';
import { VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import { useGameHostingCoordinatorGetter } from '../../GameHostingProvider';
import {
  type H2HGameInfo,
  type H2HGamePlayCard,
  type H2HGamePlayCardMap,
  type H2HGamePlayCardPrompt,
  type H2HGameRoot,
  type H2HParticipantRole,
  type H2HPlayerRole,
  type H2HRoundProgress,
  type H2HVoiceOverVariables,
} from './types';
import { useGetGroupBoundingRect } from './utils';
import { HeadToHeadFBUtils, HeadToHeadUtils, log } from './utils';

type Dependencies = {
  playerPicker: PlayerPicker;
  teamPicker: TeamPicker;
  buildVoiceOverVariables: (
    block: HeadToHeadBlock,
    progress: H2HRoundProgress
  ) => H2HVoiceOverVariables;
  now: () => number;
  emoji: {
    groupA: ReturnType<typeof useEmojisAnimation>;
    groupB: ReturnType<typeof useEmojisAnimation>;
  };
};

class PlayerPicker {
  constructor(
    private getParticipants: () => ReturnType<typeof useParticipantsAsArray>
  ) {}

  pickNext(exclude?: string[], n = 2): Nullable<Participant>[] {
    const participants = shuffle(this.getParticipants());
    const filtered = participants.filter((p) => !exclude?.includes(p.id));
    const candidates = filtered.length < n ? participants : filtered;
    const picked: Nullable<Participant>[] = [];
    for (let i = 0; i < n; i++) {
      const next = candidates[i] || null;
      // avoid picking the same team
      if (picked.map((p) => p?.teamId).includes(next?.teamId)) {
        continue;
      }
      picked.push(next);
    }
    // if not enough, just pick the first n
    if (picked.length < n) {
      for (let i = 0; i < n; i++) {
        const next = candidates[i] || null;
        if (picked.map((p) => p?.id).includes(next?.id)) {
          continue;
        }
        picked.push(next);
      }
    }
    return picked;
  }

  getNameById(id: string | null): Nullable<string> {
    if (!id) return;
    const p = this.getParticipants().find((p) => p.id === id);
    return p?.firstName || p?.username;
  }

  getNameByClientId(clientId: string | undefined): Nullable<string> {
    if (!clientId) return;
    const p = this.getParticipants().find((p) => p.clientId === clientId);
    return p?.firstName || p?.username;
  }
}

class TeamPicker {
  constructor(private getTeams: ReturnType<typeof useTeamsGetter>) {}
  pickNext(exclude?: string[], n = 2): Nullable<TeamV0>[] {
    const teams = shuffle(
      this.getTeams({
        active: true,
        updateStaffTeam: true,
        excludeStaffTeam: true,
      })
    );
    const filtered = teams.filter((t) => !exclude?.includes(t.id));
    const candidates = filtered.length < n ? teams : filtered;
    const picked: TeamV0[] = [];
    for (let i = 0; i < n; i++) {
      picked.push(candidates[i] || null);
    }
    return picked;
  }

  getNameById(id: string | undefined): Nullable<string> {
    if (!id) return;
    const team = this.getTeams({
      active: true,
      updateStaffTeam: true,
      excludeStaffTeam: true,
    }).find((t) => t.id === id);
    return team?.name;
  }
}

/**
 * This can be called at any time to "kill" the current voiceovers, such as when
 * the block is finished. In the future, consider calling `reset` when various
 * phases of the block are complete as well.
 */
class VOResetManager {
  players: (LVOBroadcastPlayer | LVOLocalPlayer)[] = [];

  register(
    player: LVOBroadcastPlayer | LVOLocalPlayer,
    trackEnded: Nullable<Promise<void>>
  ) {
    if (!trackEnded) return;
    this.players.push(player);
    trackEnded.then(() => {
      this.players = this.players.filter((p) => p !== player);
    });
  }

  reset() {
    for (const player of this.players) {
      player.stop();
    }
  }
}

class HeadToHeadGamePlayAPI {
  private voResetMan = new VOResetManager();
  private _state;
  constructor(
    venueId: string,
    svc: FirebaseService,
    private log: Logger,
    readonly deps: Dependencies,
    private cardsHandle = HeadToHeadFBUtils.CardsHandle(svc, venueId),
    private progressHandle = HeadToHeadFBUtils.ProgressHandle(svc, venueId),
    private infoHandle = HeadToHeadFBUtils.InfoHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(proxy<H2HGameRoot>(this.initialState()));
  }
  get state() {
    return this._state;
  }
  on(_aborter: AbortController): void {
    const initialState = this.initialState();
    this.cardsHandle.on((val) =>
      ValtioUtils.set(this._state, 'cards', val ?? initialState.cards)
    );
    this.progressHandle.on((val) =>
      ValtioUtils.set(this._state, 'progress', val ?? initialState.progress)
    );
    this.infoHandle.on((val) =>
      ValtioUtils.set(this._state, 'info', val ?? initialState.info)
    );
  }
  off() {
    this.cardsHandle.off();
    this.progressHandle.off();
    this.infoHandle.off();
  }
  reset() {
    ValtioUtils.reset(this._state, this.initialState());
    this.voResetMan.reset();
  }
  private initialState(): H2HGameRoot {
    return {
      cards: {},
      progress: {
        currentCardIndex: 0,
        currentCardPhase: 'ready',
        currentAGroupId: '',
        currentBGroupId: '',
        roundPhase: 'playing',
        roundPhaseChangedAt: 0,
        initedAt: 0,
        votes: {},
      },
      info: { playedGroupIds: {}, roundCount: 0 },
    };
  }

  async revealCurrentCard() {
    this.log.info('reveal card', {
      cardIndex: this._state.progress.currentCardIndex,
    });
    await this.progressHandle.update({ currentCardPhase: 'revealed' });
  }

  async nextCard() {
    const currCardIndex = this._state.progress.currentCardIndex;
    const nextCardIndex = currCardIndex + 1;
    this.log.info('next card', { currCardIndex, nextCardIndex });
    const updates: Partial<H2HRoundProgress> = {
      currentCardIndex: nextCardIndex,
      currentCardPhase: 'revealed',
    };
    // only in turns mode
    if (this._state.progress.currentTurn) {
      updates.currentTurn =
        this._state.progress.currentTurn === 'groupA' ? 'groupB' : 'groupA';
    }
    await this.progressHandle.update(updates);
  }

  async setRoundPhase(status: H2HRoundProgress['roundPhase'], debug: string) {
    this.log.info('set round phase', { status, debug });
    await this.progressHandle.update({
      roundPhase: status,
      roundPhaseChangedAt: this.deps.now(),
    });
  }

  async vote(voterUid: string, groupId: string) {
    await this.progressHandle.ref
      .child('votes')
      .child(voterUid)
      .set({ groupId, votedAt: this.deps.now() });
  }

  async sendEmoji(group: H2HPlayerRole, emoji: string, uid: string) {
    const api = this.deps.emoji[group];
    if (!api) {
      this.log.warn('emoji api not found', { group });
      return;
    }
    try {
      await api.send(emoji, uid);
    } catch (error) {
      this.log.error('fail to send emoji', error, { group, emoji, uid });
    }
  }

  async trackPlayedGroups() {
    const groupIds = [
      this._state.progress.currentAGroupId,
      this._state.progress.currentBGroupId,
    ].filter((p) => !!p);
    this.log.info('track played groups', { groupIds });
    const ref = this.infoHandle.ref.child('playedGroupIds');
    const promises = [];
    for (const groupId of groupIds) {
      if (!groupId) continue;
      promises.push(ref.child(groupId).set(this.deps.now()));
    }
    await Promise.all(promises);
  }

  async updateCurrentGroups(groupIds: string[]) {
    this.log.info('update current groups', { groupIds });
    if (groupIds.length !== 2) return;
    const updates: Partial<H2HRoundProgress> = {};
    updates.currentAGroupId = groupIds[0];
    updates.currentBGroupId = groupIds[1];
    await this.progressHandle.update(updates);
  }

  async clearGroupId(group: 'groupA' | 'groupB') {
    this.log.info('clear group id', {
      group,
      progress: ValtioUtils.detachCopy(this._state.progress),
    });
    const updates: Partial<H2HRoundProgress> = {};
    updates[group === 'groupA' ? 'currentAGroupId' : 'currentBGroupId'] = '';
    await this.progressHandle.update(updates);
  }

  async prepareCardVOLocally(
    block: HeadToHeadBlock,
    cardId: string,
    prompt: H2HGamePlayCardPrompt,
    policy = EnumsTTSRenderPolicy.TTSRenderPolicyReadCacheOnly
  ) {
    if (!prompt.voRuntimeHash) {
      this.log.info(`no voice over available`, {
        card: cardId,
        prompt: ValtioUtils.detachCopy(prompt),
      });
      return;
    }

    const runtimeVoiceOver = await HeadToHeadUtils.LookupCardRuntimeVoiceOver(
      block,
      cardId,
      prompt.voRuntimeHash
    );

    this.log.info(`voice over info`, {
      cardId,
      prompt: ValtioUtils.detachCopy(prompt),
      runtime: ValtioUtils.detachCopy(runtimeVoiceOver),
    });

    if (!runtimeVoiceOver) return;

    const progress = this._state.progress;
    const variables = VariableRegistry.FromRecord(
      this.deps.buildVoiceOverVariables(block, progress)
    );
    const req = await lvoResolveTTSRequest(
      {
        ...VoiceOverUtils.AsTTSRenderRequest(runtimeVoiceOver),
        script: runtimeVoiceOver.script,
        policy,
      },
      variables
    );

    if (!req) return;

    this.log.info(`registered card voice over`, {
      cardId,
      req,
    });

    try {
      await lvoCacheWarm(req);
      this.log.info(`loaded card voice over`, { cardId, req });
    } catch (err) {
      this.log.error(`fail to load voice over`, err2s(err), {
        cardId,
        req,
      });
    }
  }

  async playCardVOLocally(
    block: HeadToHeadBlock,
    cardId: string,
    prompt: H2HGamePlayCardPrompt,
    policy = EnumsTTSRenderPolicy.TTSRenderPolicyReadCacheOnly
  ) {
    const runtimeVoiceOver = await HeadToHeadUtils.LookupCardRuntimeVoiceOver(
      block,
      cardId,
      prompt.voRuntimeHash
    );

    if (!runtimeVoiceOver) {
      this.log.warn('voice over not found', { cardId });
      return;
    }

    const progress = this._state.progress;
    const variables = VariableRegistry.FromRecord(
      this.deps.buildVoiceOverVariables(block, progress)
    );

    const req = await lvoResolveTTSRequest(
      {
        ...VoiceOverUtils.AsTTSRenderRequest(runtimeVoiceOver),
        script: runtimeVoiceOver.script,
        policy,
      },
      variables
    );

    this.log.info('try to play card voice over', { cardId, groupId: req });
    const player = new LVOLocalPlayer(req);
    const info = await player.play();
    this.voResetMan.register(player, info?.trackEnded);
    await info?.trackEnded;
  }
}

type VoiceOverLookupKey = 'judging';

class HeadToHeadGameControlAPI {
  voResetMan = new VOResetManager();

  constructor(
    venueId: string,
    svc: FirebaseService,
    private log: Logger,
    readonly deps: Dependencies,
    private rootHandle = HeadToHeadFBUtils.RootHandle(svc, venueId)
  ) {}

  on(_aborter: AbortController): void {
    //
  }

  async initGame(block: HeadToHeadBlock) {
    const picker = HeadToHeadUtils.SingleMode(block)
      ? this.deps.playerPicker
      : this.deps.teamPicker;

    const picked = picker.pickNext();

    await this.configureGame(
      block,
      picked.map((p) => p?.id ?? ''),
      {
        playedGroupIds: {},
        roundCount: 1,
      }
    );
  }

  async configureGame(
    block: HeadToHeadBlock,
    groupIds: string[],
    info: H2HGameInfo
  ) {
    const cards: H2HGamePlayCardMap = {};
    const sourceCards = HeadToHeadUtils.TurnsMode(block)
      ? block.fields.cards
      : shuffle(block.fields.cards);
    for (const [index, card] of sourceCards.entries()) {
      const gamePlayCard = await HeadToHeadUtils.CardToGamePlay(card, index);
      cards[index] = gamePlayCard;
    }

    const progress: H2HRoundProgress = {
      currentCardIndex: 0,
      currentCardPhase: 'ready',
      currentAGroupId: groupIds[0] ?? '',
      currentBGroupId: groupIds[1] ?? '',
      roundPhase: 'playing',
      roundPhaseChangedAt: this.deps.now(),
      initedAt: this.deps.now(),
      votes: {},
    };

    if (HeadToHeadUtils.TurnsMode(block)) {
      progress.currentTurn = 'groupA';
    }

    this.registerCardVOs(
      block,
      Object.values(cards).sort((a, b) => a.index - b.index),
      progress
    ).catch((e) => this.log.error('fail to register card voice overs', e));

    await this.registerSharedVOs(block, progress);

    await this.rootHandle.set({
      cards,
      progress,
      info,
    });
  }

  async registerSharedVOs(block: HeadToHeadBlock, progress: H2HRoundProgress) {
    const variables = this.deps.buildVoiceOverVariables(block, progress);
    const judgingVoiceOver = block.fields.judgingVoiceOver;
    const ttsScripts = new TagQuery(block.fields.ttsScripts);
    const [judgingPreferred] = VoiceOverUtils.SamplePreferredFallbackScripts(
      ttsScripts.select(['judging'])
    );

    const [vo] = VoiceOverUtils.BuildRuntimeFallbackVoiceOverPlansFromLegacy(
      judgingVoiceOver,
      judgingPreferred,
      undefined,
      block.fields.ttsOptions,
      null
    );

    if (!vo) return;

    const req = await lvoTTSRequestFromPlan(
      vo.plan,
      VariableRegistry.FromRecord(variables)
    );

    try {
      await lvoCacheWarm(req);
      this.log.info('loaded judging voice over', { req });
    } catch (err) {
      this.log.error('fail to load voice over', err2s(err), {
        req,
      });
    }
  }

  // this is used to register voice overs in the backend with cache
  async registerCardVOs(
    block: HeadToHeadBlock,
    cards: H2HGamePlayCard[],
    progress: H2HRoundProgress
  ) {
    let nextTurn: 'groupA' | 'groupB' | undefined = undefined;

    const variables = VariableRegistry.FromRecord(
      this.deps.buildVoiceOverVariables(block, progress)
    );

    for (const [index, card] of cards.entries()) {
      nextTurn = nextTurn === 'groupA' ? 'groupB' : 'groupA';

      const prompts = [
        { name: 'groupA', prompt: card.groupA },
        { name: 'groupB', prompt: card.groupB },
        {
          name: 'audience',
          prompt: card.audience,
        },
      ];

      for (const { name, prompt } of prompts) {
        const logKey = `${index}-${name}`;
        const runtimeVoiceOver =
          await HeadToHeadUtils.LookupCardRuntimeVoiceOver(
            block,
            card.id,
            prompt.voRuntimeHash
          );
        const render = VoiceOverUtils.AsTTSRenderRequest(runtimeVoiceOver);

        if (!render) {
          this.log.info(`no runtime voice over found: ${logKey}`, {
            card: card.id,
            prompt,
          });
          continue;
        }

        const req = await lvoResolveTTSRequest(
          {
            ...render,
            policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
          },
          variables
        );

        lvoCacheWarm(req)
          .then(() => {
            this.log.info(`loaded runtime voice over: ${logKey}`, {
              cardId: card.id,
            });
          })
          .catch((e) => {
            this.log.error(
              `fail to register runtime voice over: ${logKey}`,
              e,
              {
                cardId: card.id,
              }
            );
          });
      }
    }
  }

  async playVO(
    block: HeadToHeadBlock,
    progress: H2HRoundProgress,
    key: VoiceOverLookupKey
  ) {
    const variables = this.deps.buildVoiceOverVariables(block, progress);
    const judgingVoiceOver = block.fields.judgingVoiceOver;
    const ttsScripts = new TagQuery(block.fields.ttsScripts);
    const [judgingPreferred] = VoiceOverUtils.SamplePreferredFallbackScripts(
      ttsScripts.select(['judging'])
    );

    const [vo] = VoiceOverUtils.BuildRuntimeFallbackVoiceOverPlansFromLegacy(
      judgingVoiceOver,
      judgingPreferred,
      undefined,
      block.fields.ttsOptions,
      null
    );

    if (!vo) return;

    const req = await lvoTTSRequestFromPlan(
      vo.plan,
      VariableRegistry.FromRecord(variables)
    );

    try {
      this.log.info('play voice over', { key });
      const player = new LVOBroadcastPlayer(req);
      const info = await player.play();
      this.voResetMan.register(player, info.tracksEnded);
    } catch (err) {
      this.log.error('fail to play voice over', err2s(err), {
        req,
      });
    }
  }

  async resetGame() {
    this.log.info('reset game');
    await this.rootHandle.remove();
    this.voResetMan.reset();
  }
}

function useHeadToHeadContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('HeadToHeadContext is not in the tree!');
  return ctx;
}

export function useHeadToHeadGamePlayAPI(): Context['gamePlayAPI'] {
  return useHeadToHeadContext().gamePlayAPI;
}

export function useHeadToHeadGameControlAPI(): Context['gameControlAPI'] {
  return useHeadToHeadContext().gameControlAPI;
}

function useHeadToHeadGamePlayCards(): H2HGamePlayCard[] {
  const api = useHeadToHeadGamePlayAPI();
  const cardMap = useSnapshot(api.state).cards;
  return useMemo(
    () => Object.values(cardMap).sort((a, b) => a.index - b.index),
    [cardMap]
  );
}

export function useHeadToHeadGameProgress() {
  const api = useHeadToHeadGamePlayAPI();
  return useSnapshot(api.state).progress;
}

export function useHeadToHeadGameInfo() {
  const api = useHeadToHeadGamePlayAPI();
  return useSnapshot(api.state).info;
}

export function useCurrentHeadToHeadGameCard(): H2HGamePlayCard | undefined {
  const cards = useHeadToHeadGamePlayCards();
  const progress = useHeadToHeadGameProgress();
  return cards[progress.currentCardIndex];
}

export function useMyCurrentHeadToHeadRole(
  block: HeadToHeadBlock
): H2HParticipantRole {
  const progress = useHeadToHeadGameProgress();
  const me = useMyInstance();
  const groupId = HeadToHeadUtils.SingleMode(block) ? me?.id : me?.teamId;
  return useMemo(() => {
    if (groupId === progress.currentAGroupId) return 'groupA';
    if (groupId === progress.currentBGroupId) return 'groupB';
    return 'audience';
  }, [groupId, progress.currentAGroupId, progress.currentBGroupId]);
}

export function useMyCurrentCardPrompt(block: HeadToHeadBlock) {
  const card = useCurrentHeadToHeadGameCard();
  const currentTurn = useHeadToHeadGameProgress().currentTurn;
  return useMyCardPrompt(block, card, currentTurn);
}

export function useMyCardPrompt(
  block: HeadToHeadBlock,
  card: H2HGamePlayCard | undefined,
  turn?: H2HRoundProgress['currentTurn']
) {
  const role = useMyCurrentHeadToHeadRole(block);
  const turnMode = HeadToHeadUtils.TurnsMode(block);
  return useMemo(() => {
    if (!card) return { text: '', visibility: [] };
    if (turnMode) {
      if (role === 'audience' || !turn) return card.audience;
      return role === turn ? card.groupA : card.groupB;
    } else {
      if (role === 'groupA') return card.groupA;
      if (role === 'groupB') return card.groupB;
      return card.audience;
    }
  }, [card, turn, role, turnMode]);
}

export function useNextHeadToHeadGameCard(): H2HGamePlayCard | undefined {
  const cards = useHeadToHeadGamePlayCards();
  const progress = useHeadToHeadGameProgress();
  const nextCardIndex =
    progress.currentCardPhase === 'ready'
      ? progress.currentCardIndex
      : progress.currentCardIndex + 1;
  return cards[nextCardIndex];
}

export function useMyNextCardPrompt(block: HeadToHeadBlock) {
  const progress = useHeadToHeadGameProgress();
  const card = useNextHeadToHeadGameCard();
  const currentTurn = progress.currentTurn;
  return useMyCardPrompt(
    block,
    card,
    currentTurn === 'groupA' ? 'groupB' : 'groupA'
  );
}

export function useCurrentCardVideoMediaAsset(block: HeadToHeadBlock) {
  const card = useCurrentHeadToHeadGameCard();
  return useMemo(() => {
    const prompts = [card?.audience, card?.groupA, card?.groupB];
    for (const prompt of prompts) {
      if (prompt?.mediaType === EnumsMediaType.MediaTypeVideo) {
        return HeadToHeadUtils.LookupCardMediaAssets(block, prompt.mediaId);
      }
    }
  }, [block, card?.audience, card?.groupA, card?.groupB]);
}

export function useMyVote() {
  const progress = useHeadToHeadGameProgress();
  const me = useMyInstance();
  return progress.votes?.[me?.id ?? ''];
}

export function useGroupVoters(groupId: string) {
  const progress = useHeadToHeadGameProgress();
  const sortedVotes = useMemo(() => {
    const votes = progress.votes ?? {};
    return Object.entries(votes)
      .map(([voterId, vote]) => ({ voterId, ...vote }))
      .filter((v) => v.groupId === groupId)
      .sort((a, b) => a.votedAt - b.votedAt);
  }, [progress.votes, groupId]);
  return sortedVotes;
}

export function useWinnerGroupIds(sentiment = 1) {
  const progress = useHeadToHeadGameProgress();
  const numOfAVotes = useGroupVoters(progress.currentAGroupId).length;
  const numOfBVotes = useGroupVoters(progress.currentBGroupId).length;

  const winnerGroupIds = useMemo(() => {
    if (numOfAVotes === numOfBVotes) {
      return [progress.currentAGroupId, progress.currentBGroupId];
    }
    const diff = (numOfAVotes - numOfBVotes) * sentiment;
    if (diff > 0) {
      return [progress.currentAGroupId];
    } else {
      return [progress.currentBGroupId];
    }
  }, [
    numOfAVotes,
    numOfBVotes,
    progress.currentAGroupId,
    progress.currentBGroupId,
    sentiment,
  ]);

  return winnerGroupIds;
}

function useGroupPlayerIds(block: HeadToHeadBlock, groupId: string) {
  const singleMode = HeadToHeadUtils.SingleMode(block);
  const p = useLastJoinedParticipantByUserId(groupId);
  const teamMemberIds = useTeamMembers(groupId)?.map((m) => m.id);
  return useMemo(
    () => (singleMode ? (p?.id ? [p.id] : []) : teamMemberIds ?? []),
    [singleMode, p, teamMemberIds]
  );
}

export function useTotalVoters(block: HeadToHeadBlock) {
  const progress = useHeadToHeadGameProgress();
  const candidates = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'staff:false'],
  });
  const aGroupPlayerIds = useGroupPlayerIds(block, progress.currentAGroupId);
  const bGroupPlayerIds = useGroupPlayerIds(block, progress.currentBGroupId);
  const playerVotable = usePlayerVotable();
  return useMemo(() => {
    switch (block.fields.judgingUserGroup) {
      case EnumsH2HJudgingUserGroup.H2HJudgingUserGroupGameCoordinator:
        return 1;
      case EnumsH2HJudgingUserGroup.H2HJudgingUserGroupAudience:
        if (playerVotable) return candidates.length;
        const set = new Set<string>([...aGroupPlayerIds, ...bGroupPlayerIds]);
        return candidates.filter((c) => !set.has(c.id)).length;
      default:
        assertExhaustive(block.fields.judgingUserGroup);
        return Number.POSITIVE_INFINITY;
    }
  }, [
    aGroupPlayerIds,
    bGroupPlayerIds,
    block.fields.judgingUserGroup,
    candidates,
    playerVotable,
  ]);
}

type Context = {
  gamePlayAPI: HeadToHeadGamePlayAPI;
  gameControlAPI: HeadToHeadGameControlAPI;
};

const context = React.createContext<Context | null>(null);

export function usePlayerVotable() {
  return useFeatureQueryParam('head-to-head-player-voting');
}

export function useNextRoundPhaseAfterPlaying(block: HeadToHeadBlock) {
  const judgingEnabled = HeadToHeadUtils.JudgingEnabled(block);
  const judgingAfterGame = HeadToHeadUtils.JudgingAfterGame(block);
  const replayable = block.fields.replayable;

  return useMemo(() => {
    if (judgingEnabled) {
      if (judgingAfterGame) {
        return 'judging-after-playing';
      } else {
        return 'reveal-winner';
      }
    }
    if (replayable) {
      return 'select-next';
    }
  }, [judgingEnabled, judgingAfterGame, replayable]);
}

export function HeadToHeadProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();

  const participantsGetter = useParticipantsAsArrayGetter();
  const teamsGetter = useTeamsGetter();
  const clock = useClock();
  const getCoordinator = useGameHostingCoordinatorGetter();

  const groupAEmoji = useEmojisAnimation(
    HeadToHeadFBUtils.Path(venueId, 'group-a-emoji'),
    useGetGroupBoundingRect('h2h-group-a-anchor')
  );
  const groupBEmoji = useEmojisAnimation(
    HeadToHeadFBUtils.Path(venueId, 'group-b-emoji'),
    useGetGroupBoundingRect('h2h-group-b-anchor')
  );

  const ctx: Context = useMemo(() => {
    const getParticipants = () =>
      participantsGetter({
        filters: [
          'staff:false',
          'host:false',
          'cohost:false',
          'status:connected',
          'team:true',
        ],
      });
    const playerPicker = new PlayerPicker(getParticipants);
    const teamPicker = new TeamPicker(teamsGetter);
    const deps: Dependencies = {
      playerPicker,
      teamPicker,
      buildVoiceOverVariables: (block, progress) => {
        const picker = HeadToHeadUtils.SingleMode(block)
          ? playerPicker
          : teamPicker;
        return {
          pinkSideNames:
            picker.getNameById(progress.currentAGroupId) ?? 'Pink Side',
          blueSideNames:
            picker.getNameById(progress.currentBGroupId) ?? 'Blue Side',
          coordinatorName:
            playerPicker.getNameByClientId(getCoordinator()?.clientId) ??
            'Organizer',
        };
      },
      now: clock.now.bind(clock),
      emoji: {
        groupA: groupAEmoji,
        groupB: groupBEmoji,
      },
    };
    return {
      gamePlayAPI: new HeadToHeadGamePlayAPI(venueId, svc, log, deps),
      gameControlAPI: new HeadToHeadGameControlAPI(venueId, svc, log, deps),
    };
  }, [
    teamsGetter,
    clock,
    groupAEmoji,
    groupBEmoji,
    venueId,
    svc,
    participantsGetter,
    getCoordinator,
  ]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
