import { FastAverageColor } from 'fast-average-color';
import { type ReactNode, useEffect, useState } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import { xDomainifyUrl } from '../../utils/common';
import { createProvider } from '../../utils/createProvider';
import { rgbToHsl } from '../../utils/css';
import { MediaUtils } from '../../utils/media';
import { useOrg } from '../Lobby/hooks';
import { useVenueOrgId } from '../Venue/useVenueOrgId';

function isCloseToGrayscale(hsl: number[]): boolean {
  return hsl[1] < 0.1 || hsl[2] < 0.1 || hsl[2] > 0.9;
}

class ColorFormatter {
  constructor(
    public readonly rgba_255: [number, number, number, number],
    public readonly hsla_0_1 = [
      ...rgbToHsl(rgba_255[0], rgba_255[1], rgba_255[2]),
      rgba_255[3] / 255,
    ]
  ) {}

  cssRGBA(a: number = this.rgba_255[3] / 255) {
    const parts = [
      this.rgba_255[0],
      this.rgba_255[1],
      this.rgba_255[2],
      a,
    ].join(',');

    return `rgba(${parts})`;
  }

  cssHSLA(a: number = this.hsla_0_1[3]) {
    const parts = [
      this.hsla_0_1[0] * 360,
      `${this.hsla_0_1[1] * 100}%`,
      `${this.hsla_0_1[2] * 100}%`,
      a,
    ].join(',');

    return `hsla(${parts})`;
  }
}

type ProviderValue = {
  /** The actual color that was pulled out of the logo */
  derivedColor: Nullable<ColorFormatter>;
  /** The derived color, or if grayscale, the default text color. */
  scoreboardColor: Nullable<ColorFormatter>;
  /** The derived color, or if grayscale, the default background color. */
  backgroundColor: Nullable<ColorFormatter>;
};

const { useCreatedContext, Provider } = createProvider<ProviderValue>(
  'VenueOrgLogoAverageColor'
);

const DEFAULT_SCOREBOARD_COLOR = new ColorFormatter([255, 255, 255, 255]);
const DEFAULT_BACKGROUND_COLOR = new ColorFormatter([165, 132, 48, 255]);

export function VenueOrgLogoAverageColorProvider(props: {
  children?: ReactNode;
}) {
  const [value, setValue] = useState<ProviderValue>(() => ({
    derivedColor: null,
    scoreboardColor: DEFAULT_SCOREBOARD_COLOR,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
  }));

  const orgId = useVenueOrgId();

  const { data: org } = useOrg(orgId ?? undefined);

  const logoSrc = MediaUtils.PickMediaUrl(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  useEffect(() => {
    async function exec() {
      if (!logoSrc) {
        setValue({
          derivedColor: null,
          scoreboardColor: DEFAULT_SCOREBOARD_COLOR,
          backgroundColor: DEFAULT_BACKGROUND_COLOR,
        });

        return;
      }

      const fac = new FastAverageColor();
      const result = await fac.getColorAsync(xDomainifyUrl(logoSrc), {
        algorithm: 'sqrt',
        ignoredColor: [
          [255, 255, 255, 255, 5],
          [0, 0, 0, 255, 5],
        ],
      });

      const next = new ColorFormatter(result.value);
      const isGrayscale = isCloseToGrayscale(next.hsla_0_1);

      setValue({
        derivedColor: next,
        scoreboardColor: isGrayscale ? DEFAULT_SCOREBOARD_COLOR : next,
        backgroundColor: isGrayscale ? DEFAULT_BACKGROUND_COLOR : next,
      });
    }

    exec();
  }, [logoSrc]);

  return <Provider value={value}>{props.children}</Provider>;
}

export function useVenueOrgScoreboardColor() {
  const ctx = useCreatedContext();
  return ctx.scoreboardColor;
}

export function useVenueOrgBackgroundColor() {
  const ctx = useCreatedContext();
  return ctx.backgroundColor;
}
