import { useEffectOnce } from 'react-use';

import {
  type ScoreboardBlock,
  ScoreboardBlockGameSessionStatus,
} from '@lp-lib/game';

import { assertExhaustive } from '../../../../utils/common';
import {
  LVOBroadcastPlayer,
  lvoTTSRequestFromPlan,
} from '../../../VoiceOver/LocalizedVoiceOvers';
import { VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import {
  useCreateGameInfoSnapshot,
  useGameSessionStatus,
  useGetOndGameCurrentPlaybackItem,
} from '../../hooks';
import { ondWaitReadyForSkip } from '../../OndPhaseRunner/OndPhaseRunner';
import { type GameControlProps } from '../Common/GameControl/types';

function Play() {
  const getCurrentPlaybackItem = useGetOndGameCurrentPlaybackItem();
  const createSnapshot = useCreateGameInfoSnapshot();

  useEffectOnce(() => {
    async function run() {
      try {
        const playbackItem = getCurrentPlaybackItem();
        const voiceOverPlans = playbackItem?.voiceOverPlans ?? [];
        if (voiceOverPlans.length === 0) return;
        const vo = voiceOverPlans[0];
        const reg =
          createSnapshot()?.variableRegistry ?? new VariableRegistry();
        const req = await lvoTTSRequestFromPlan(vo.plan, reg);
        const player = new LVOBroadcastPlayer(req);
        const info = await player.play();
        await info.tracksEnded;
      } finally {
        await ondWaitReadyForSkip();
      }
    }
    run();
  });
  return null;
}

export function ScoreboardBlockGameControl(
  _props: GameControlProps<ScoreboardBlock>
): JSX.Element | null {
  const gss = useGameSessionStatus<ScoreboardBlockGameSessionStatus>();

  switch (gss) {
    case ScoreboardBlockGameSessionStatus.SCOREBOARD:
      return <Play />;
    case ScoreboardBlockGameSessionStatus.LOADED:
    case ScoreboardBlockGameSessionStatus.PRESENTING:
    case ScoreboardBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
