import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import Select, { type SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {
  EnumsHiddenPicturePenaltyResetStrategy,
  EnumsMediaScene,
} from '@lp-lib/api-service-client/public';
import {
  type BlockMediaFields,
  type HiddenPicture,
  type HiddenPictureBlock,
  type HiddenPictureBlockMedia,
  type HotSpotV2,
} from '@lp-lib/game';
import { type Media, type MediaData } from '@lp-lib/media';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { uuidv4 } from '../../../../utils/common';
import { MediaPickPriorityHD, MediaUtils } from '../../../../utils/media';
import { buildReactSelectStyles } from '../../../../utils/react-select';
import { DragDropList } from '../../../common/DragDrop';
import { type Option } from '../../../common/Utilities';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../../ConfirmCancelModalContext';
import { MenuIcon } from '../../../icons/MenuIcon';
import { Loading } from '../../../Loading';
import { MiniMediaEditor } from '../../../MediaUploader/MiniMediaEditor';
import { MiniMediaUploader } from '../../../MediaUploader/MiniMediaUploader';
import { useMediaUploader } from '../../../MediaUploader/useMediaUploader';
import { useBlockEditorStore } from '../../../RoutedBlock';
import {
  AdditionalSettings as AdditionalSettingsSection,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  formatDurationOption,
  getNewDurationOptionData,
  isValidNewDurationOption,
  RHFCheckbox,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import { EditHiddenPictureButton } from './HiddenPictureEditor';
import { type AdditionalSettings, type AsymmetricSettings } from './types';

function secondsToTimeOption(secs: number): Option<number> {
  if (secs < 60) {
    return { label: `${secs} ${pluralize('second', secs)}`, value: secs };
  }

  const mins = secs / 60;
  return { label: `${mins} ${pluralize('minute', mins)}`, value: secs };
}

const gameTimeOptions = [
  10, 15, 20, 30, 45, 60, 90, 120, 180, 240, 300, 600, 900,
].map(secondsToTimeOption);

function HiddenPictureMediaPreview(props: {
  picture: HiddenPicture;
}): JSX.Element | null {
  const mediaUrl = MediaUtils.PickMediaUrl(props.picture.mainMedia, {
    priority: MediaPickPriorityHD,
    videoThumbnail: 'first',
  });
  if (!mediaUrl) return null;
  return (
    <img
      src={mediaUrl}
      alt={props.picture.name}
      className='w-full h-full object-cover'
    />
  );
}

function HiddenPicturesEditor(props: {
  block: HiddenPictureBlock;
  onUpdate: (value: HiddenPicture[]) => void;
}): JSX.Element {
  const { block, onUpdate } = props;
  const triggerConfirmationModal = useAwaitFullScreenConfirmCancelModal();

  const [pictures, setPictures] = useState<HiddenPicture[]>(
    block.fields.pictures ? [...block.fields.pictures] : []
  );

  const handleAddMore = (pictures: HiddenPicture[]) => {
    setPictures((current) => {
      const next = [...current, ...pictures];
      onUpdate(next);
      return next;
    });
  };

  const handleUpdate = (updated: HiddenPicture) => {
    setPictures((current) => {
      const index = current.findIndex((c) => c.id === updated.id);
      if (index === -1) return current;
      const next = [...current];
      next[index] = updated;
      onUpdate(next);
      return next;
    });
  };

  const handleApplyAdditionalSettingsToAll = async (
    settings: AdditionalSettings
  ) => {
    setPictures((current) => {
      const next = current.map((p) => ({ ...p, ...settings }));
      onUpdate(next);
      return next;
    });
  };

  const handleApplyHotSpotsToAll = async (hotSpotsV2: HotSpotV2[]) => {
    setPictures((current) => {
      const next = current.map((p) => ({ ...p, hotSpotsV2 }));
      onUpdate(next);
      return next;
    });
  };

  const handleApplyAsymmetricSettingsToAll = async (
    settings: AsymmetricSettings
  ) => {
    setPictures((current) => {
      const next = current.map((p) => ({ ...p, ...settings }));
      onUpdate(next);
      return next;
    });
  };

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement>,
    deleted: HiddenPicture
  ) => {
    const skipConfirmation = e.shiftKey;
    if (!skipConfirmation) {
      const { result } = await triggerConfirmationModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='px-5 py-2'>
            <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
              Do you want to delete this item? This action cannot be undone.
            </ConfirmCancelModalText>
          </div>
        ),
        cancelBtnLabel: 'Cancel',
        confirmBtnLabel: 'Delete',
        confirmBtnVariant: 'delete',
      });
      if (result !== 'confirmed') {
        return;
      }
    }

    setPictures((current) => {
      const index = current.findIndex((c) => c.id === deleted.id);
      if (index === -1) return current;
      const next = [...current];
      next.splice(index, 1);
      onUpdate(next);
      return next;
    });
  };

  const handleMove = (fromIndex: number, toIndex: number) => {
    setPictures((current) => {
      const next = [...current];
      next.splice(toIndex, 0, next.splice(fromIndex, 1)[0]);
      onUpdate(next);
      return next;
    });
  };

  const handleDuplicate = (index: number) => {
    setPictures((current) => {
      const next = [...current];
      const picture = { ...next[index] };
      const newPicture = {
        ...picture,
        id: uuidv4(),
        name: picture.name.length > 0 ? `Copy of ${picture.name}` : 'Untitled',
        hotSpotsV2: (picture.hotSpotsV2 ?? []).map((hotSpot) => ({
          ...hotSpot,
          id: uuidv4(),
        })),
      };
      next.splice(index + 1, 0, newPicture);
      onUpdate(next);
      return next;
    });
  };

  return (
    <div>
      <div className='w-full flex items-center justify-between'>
        <div className='text-white font-bold mb-1'>
          Hidden Pictures ({block.fields.pictures?.length ?? 0})
        </div>

        <MultiImageUploader block={props.block} onUpload={handleAddMore} />
      </div>
      <div className='mt-2'>
        <DragDropList
          type={`${block.id}-pictures`}
          items={pictures}
          onMove={handleMove}
          render={({ item, index, drag, ref, style }) => {
            return (
              <div
                ref={ref}
                className={`flex items-center gap-2 ${
                  index === 0 ? 'mt-0' : 'mt-1'
                }`}
                style={style}
              >
                <div ref={drag} className='cursor-move'>
                  <MenuIcon className='w-5 h-5 fill-current' />
                </div>

                <div className='w-full bg-black p-4 rounded-lg flex items-center gap-4 group border border-transparent hover:border-primary'>
                  <div className='w-1/4 flex-none'>
                    <div className='aspect-w-16 aspect-h-9 bg-lp-gray-003 rounded-lg overflow-hidden'>
                      <HiddenPictureMediaPreview picture={item} />
                    </div>
                  </div>
                  <p className='text-white truncate'>
                    {item.name || 'Untitled'}
                  </p>
                  <div className='ml-auto flex items-center gap-3'>
                    <button
                      type='button'
                      className={`
                        flex-none 
                        invisible group-hover:visible
                        text-red-002 text-sms underline
                      `}
                      onClick={(e) => handleDelete(e, item)}
                    >
                      Delete
                    </button>
                    <button
                      type='button'
                      className={`
                        flex-none
                        invisible group-hover:visible
                        text-icon-gray text-sms underline
                      `}
                      onClick={() => handleDuplicate(index)}
                    >
                      Duplicate
                    </button>
                    <EditHiddenPictureButton
                      initialData={item}
                      onSubmit={(data) => {
                        handleUpdate({ ...item, ...data });
                      }}
                      showApplyAdditionalSettingsToAllButton={
                        pictures.length > 1
                      }
                      onApplyAdditionalSettingsToAll={
                        handleApplyAdditionalSettingsToAll
                      }
                      showApplyHotSpotsToAllButton={pictures.length > 1}
                      onApplyHotSpotsToAll={handleApplyHotSpotsToAll}
                      showApplyAsymmetricSettingsToAllButton={
                        pictures.length > 1
                      }
                      onApplyAsymmetricSettingsToAll={
                        handleApplyAsymmetricSettingsToAll
                      }
                    />
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}

function MultiImageUploader(props: {
  block: HiddenPictureBlock;
  onUpload: (pictures: HiddenPicture[]) => void;
}) {
  const triggerConfirmationModal = useAwaitFullScreenConfirmCancelModal();

  const [numUploads, setNumUploads] = useState(0);
  const [numUploadsCompleted, setNumUploadsCompleted] = useState(0);

  const { inputElement, isUploading } = useMediaUploader({
    id: `${props.block.id}-multi-image-uploader`,
    image: true,
    video: true,
    audio: false,
    multiple: true,
    onBeforeUpload: (files) => {
      setNumUploads(Object.keys(files).length);
      setNumUploadsCompleted(0);
      return true;
    },
    onUploadSuccess: () => {
      setNumUploadsCompleted((current) => current + 1);
    },
    onUploadFailed: () => {
      setNumUploadsCompleted((current) => current + 1);
    },
    onComplete: async (results) => {
      if (results.failed.length > 0) {
        await triggerConfirmationModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='px-5 py-2'>
              <ConfirmCancelModalHeading>
                Upload Failure
              </ConfirmCancelModalHeading>
              <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
                <>
                  {results.successful.length > 0 && (
                    <>
                      Successfully uploaded{' '}
                      <span className='font-bold'>
                        {results.successful.length}
                      </span>{' '}
                      {pluralize('image', results.successful.length)}.{' '}
                    </>
                  )}
                  The following{' '}
                  <span className='font-bold'>{results.failed.length}</span>{' '}
                  {pluralize('image', results.failed.length)} failed to upload
                  successfully. Please try again.
                  <ul className='mt-2 text-xs text-left'>
                    {results.failed.map((result) => (
                      <li key={result.id}>{result.name}</li>
                    ))}
                  </ul>
                </>
              </ConfirmCancelModalText>
            </div>
          ),
          confirmOnly: true,
          confirmBtnLabel: 'Okay',
        });
      }

      const pictures: HiddenPicture[] = [];
      for (const result of results.successful) {
        const mainMedia = result.response?.body.media as Media;
        if (!mainMedia) continue;
        pictures.push({
          id: uuidv4(),
          name: result.name,
          sequenced: false,
          everyoneClicks: false,
          hotSpotsV2: [],
          tool: 'none',
          mainMedia,
          mainMediaData: {
            id: mainMedia.id,
          },
          question: '',
          incorrectAnswerPenalty: 0,
          asymmetricGamePlay: false,
          asymmetricMedia: null,
          asymmetricMediaData: null,
        });
      }
      pictures.sort((a, b) => a.name.localeCompare(b.name));
      props.onUpload(pictures);
    },
  });

  return (
    <>
      {isUploading && (
        <div className='fixed inset-0 z-10 bg-lp-black-004'>
          <div className='w-full h-full flex flex-col items-center justify-center text-center text-icon-gray'>
            <Loading text='' />
            <div className='mt-2'>
              <p className='text-white text-lg font-bold'>Uploading</p>
              <p className='mt-1'>
                This may take a while depending on the number of images you
                selected.
              </p>
              <p className='mt-1 font-mono'>
                {numUploadsCompleted} / {numUploads}
              </p>
            </div>
          </div>
        </div>
      )}

      <label
        className={`
        btn underline text-icon-gray text-sms cursor-pointer
        ${isUploading ? 'pointer-events-none' : ''}
      `}
      >
        Add Image(s)
        <div className='hidden w-0 h-0'>{inputElement}</div>
      </label>
    </>
  );
}

type PenaltyResetOption = Option<EnumsHiddenPicturePenaltyResetStrategy> & {
  description: string;
};

const penaltyResetOptions: PenaltyResetOption[] = [
  {
    label: 'Never Reset',
    value:
      EnumsHiddenPicturePenaltyResetStrategy.HiddenPicturePenaltyResetStrategyNever,
    description:
      'A team’s penalty count will accumulate throughout the block, across all pictures.',
  },
  {
    label: 'After Each Picture',
    value:
      EnumsHiddenPicturePenaltyResetStrategy.HiddenPicturePenaltyResetStrategyPerPicture,
    description:
      'A team’s penalty count will reset after each picture is completed, i.e., penalties are counted per picture.',
  },
];

function PenaltyResetStrategySelect(props: {
  value: EnumsHiddenPicturePenaltyResetStrategy | null | undefined;
  onChange: (value: EnumsHiddenPicturePenaltyResetStrategy) => void;
}) {
  const styles = useMemo(
    () => buildReactSelectStyles<PenaltyResetOption>(),
    []
  );

  const selected = useMemo(() => {
    return penaltyResetOptions.find((o) => o.value === props.value) ?? null;
  }, [props.value]);

  const handleChange = useLiveCallback(
    (option: SingleValue<PenaltyResetOption>) => {
      if (option) {
        props.onChange(option.value);
      }
    }
  );

  return (
    <Select<PenaltyResetOption>
      classNamePrefix='select-box-v2'
      className='w-full text-xs'
      styles={styles}
      value={selected}
      options={penaltyResetOptions}
      onChange={handleChange}
      isSearchable={false}
    />
  );
}

export function HiddenPictureBlockEditor(
  props: EditorProps<HiddenPictureBlock>
): JSX.Element | null {
  const { block } = props;
  const store = useBlockEditorStore();
  const { updateField } = useEditor(props);

  const handleMediaChange = (
    field: keyof BlockMediaFields<HiddenPictureBlockMedia>,
    mediaData: MediaData | null,
    media: Media | null
  ) => {
    store.updateBlockMediaV2<HiddenPictureBlockMedia>(
      block.id,
      field,
      mediaData,
      media
    );
  };

  const selectedPenaltyResetOption = useMemo(() => {
    return penaltyResetOptions.find(
      (o) => o.value === block.fields.penaltyResetStrategy
    );
  }, [block.fields.penaltyResetStrategy]);

  const styles = useMemo(() => buildReactSelectStyles<Option<number>>(), []);

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettingsSection>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettingsSection>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white mb-7'>Hidden Picture</h2>
        <div className='w-full text-white'>
          <section className='w-full grid grid-cols-5 gap-10'>
            <div className='col-span-3 flex flex-col gap-4'>
              <HiddenPicturesEditor
                block={block}
                onUpdate={(updated) => updateField('pictures', updated)}
              />
            </div>
            <div className='col-span-2 flex flex-col gap-10'>
              <label htmlFor='gameTimeSec'>
                <span className='text-white font-bold'>Game Timer</span>
                <CreatableSelect<Option<number>>
                  options={gameTimeOptions}
                  name='gameTimeSec'
                  className={`select-box text-white`}
                  classNamePrefix='select-box-v2'
                  defaultValue={
                    gameTimeOptions.find(
                      (o) => o.value === block.fields.gameTimeSec
                    ) ?? {
                      value: block.fields.gameTimeSec,
                      label: formatDurationOption(block.fields.gameTimeSec),
                    }
                  }
                  onChange={(option) => {
                    if (!option?.value) return;
                    updateField('gameTimeSec', option.value);
                  }}
                  formatCreateLabel={formatDurationOption}
                  isValidNewOption={isValidNewDurationOption}
                  getNewOptionData={getNewDurationOptionData}
                  createOptionPosition='first'
                  styles={styles}
                />
              </label>
              <div className='flex gap-3'>
                <div>
                  <div className='text-white font-bold mb-1'>Success Image</div>
                  <div className='w-full text-icon-gray text-sms font-normal'>
                    This image will show up whenever an item is successfully
                    clicked. (Image should be a transparent square 5MB max)
                  </div>
                </div>
                <div className='flex-none'>
                  <MiniMediaUploader
                    uploaderId={`${block.id}-successMedia`}
                    media={block.fields.successMedia}
                    onDelete={() =>
                      handleMediaChange('successMedia', null, null)
                    }
                    onUploadSuccess={(media) =>
                      handleMediaChange(
                        'successMedia',
                        {
                          id: media.id,
                        },
                        media
                      )
                    }
                    customBgStyle='bg-black'
                    video={false}
                    noPlayIcon
                  />
                </div>
              </div>
              <div className='flex gap-3'>
                <div>
                  <div className='text-white font-bold mb-1'>Success Audio</div>
                  <div className='w-full text-icon-gray text-sms font-normal'>
                    This audio file will play whenever an item is successfully
                    clicked (Clip should be short ~1 sec).
                  </div>
                </div>
                <div className='flex-none'>
                  <MiniMediaEditor
                    id={`${block.id}-successAudioMedia`}
                    media={block.fields.successAudioMedia}
                    mediaData={block.fields.successAudioMediaData}
                    onChange={(mediaData, media) => {
                      handleMediaChange('successAudioMedia', mediaData, media);
                    }}
                    styles={{
                      bg: 'bg-black',
                    }}
                    audio
                    volumeSelectable
                  />
                </div>
              </div>
              <div className='flex gap-3'>
                <div>
                  <div className='text-white font-bold mb-1'>Fail Image</div>
                  <div className='w-full text-icon-gray text-sms font-normal'>
                    This image will show up whenever an item worth{' '}
                    <span className='font-bold'>negative points</span> is
                    clicked. (Image should be a transparent square 5MB max)
                  </div>
                </div>
                <div className='flex-none'>
                  <MiniMediaUploader
                    uploaderId={`${block.id}-failMedia`}
                    media={block.fields.failMedia}
                    onDelete={() => handleMediaChange('failMedia', null, null)}
                    onUploadSuccess={(media) =>
                      handleMediaChange(
                        'failMedia',
                        {
                          id: media.id,
                        },
                        media
                      )
                    }
                    customBgStyle='bg-black'
                    video={false}
                    noPlayIcon
                  />
                </div>
              </div>
              <div className='flex gap-3'>
                <div>
                  <div className='text-white font-bold mb-1'>Fail Audio</div>
                  <div className='w-full text-icon-gray text-sms font-normal'>
                    This audio file will play whenever an item worth{' '}
                    <span className='font-bold'>negative points</span> is
                    clicked (Clip should be short ~1 sec).
                  </div>
                </div>
                <div className='flex-none'>
                  <MiniMediaEditor
                    id={`${block.id}-failAudioMedia`}
                    media={block.fields.failAudioMedia}
                    mediaData={block.fields.failAudioMediaData}
                    onChange={(mediaData, media) => {
                      handleMediaChange('failAudioMedia', mediaData, media);
                    }}
                    styles={{
                      bg: 'bg-black',
                    }}
                    audio
                    volumeSelectable
                  />
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='text-white font-bold mb-1'>Time Bonus</div>

                <div className='flex-1'>
                  <div className='flex-1 flex items-center'>
                    <input
                      type='number'
                      defaultValue={
                        block.fields.bonusPointsPerRemainingSec ?? undefined
                      }
                      className='field flex-1 h-10 m-0 w-full rounded-r-none'
                      onBlur={(e) => {
                        const value = e.target.valueAsNumber;
                        if (isNaN(value)) return;
                        updateField('bonusPointsPerRemainingSec', value);
                      }}
                    />
                    <div className='bg-layer-002 rounded-r-xl h-10 flex items-center justify-center border-secondary border border-l-0 font-bold text-sms text-white px-3'>
                      points/sec
                    </div>
                  </div>
                  <div className='text-xs text-right text-icon-gray mt-1'>
                    {Math.round(
                      block.fields.gameTimeSec *
                        block.fields.bonusPointsPerRemainingSec
                    )}{' '}
                    points possible
                  </div>
                </div>

                <div className='w-full text-icon-gray text-sms font-normal'>
                  Defines the number of bonus points to award if a team
                  completes all the pictures. Bonus points will be awarded for
                  each second remaining on the timer when the team finishes.
                  Partial points are supported, and the final score will be
                  rounded to the nearest whole number.
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <RHFCheckbox<HiddenPictureBlock>
                  label='Max Penalty Limit'
                  name='maxPenaltyLimit'
                  value={!!block.fields.maxPenaltyLimit}
                  onChange={async (_, checked: boolean) => {
                    await updateField('maxPenaltyLimit', checked ? 5 : 0);
                    await updateField(
                      'penaltyResetStrategy',
                      EnumsHiddenPicturePenaltyResetStrategy.HiddenPicturePenaltyResetStrategyNever
                    );
                  }}
                />

                {Boolean(block.fields.maxPenaltyLimit) && (
                  <div className='flex-1 flex items-center'>
                    <input
                      type='number'
                      defaultValue={block.fields.maxPenaltyLimit ?? undefined}
                      className='field flex-1 h-10 m-0 w-full rounded-r-none'
                      min={1}
                      disabled={!block.fields.maxPenaltyLimit}
                      onBlur={(e) => {
                        if (e.target.valueAsNumber)
                          updateField(
                            'maxPenaltyLimit',
                            e.target.valueAsNumber
                          );
                      }}
                    />
                    <div className='bg-layer-002 rounded-r-xl h-10 flex items-center justify-center border-secondary border border-l-0 font-bold text-sms text-white px-3'>
                      failures
                    </div>
                  </div>
                )}

                <div className='w-full text-icon-gray text-sms font-normal'>
                  The maximum number of penalty clicks allowed. A penalty click
                  is any click that does not result in points being granted.
                  When this limit is met, users will see the Failure Animation,
                  and cannot progress through the block.
                </div>
              </div>
              {Boolean(block.fields.maxPenaltyLimit) && (
                <>
                  <div className='flex flex-col gap-3'>
                    <div className='text-white font-bold mb-1'>
                      Max Penalty Limit Label
                    </div>
                    <input
                      type='text'
                      defaultValue={
                        block.fields.maxPenaltyLimitLabel ?? undefined
                      }
                      className='field h-10 m-0 w-full'
                      onBlur={(e) => {
                        updateField('maxPenaltyLimitLabel', e.target.value);
                      }}
                      placeholder='Mistakes until Detnotation, e.g.'
                      maxLength={50}
                    />
                    <div className='w-full text-icon-gray text-sms font-normal'>
                      This text will display below the failure limit in the
                      sidebar.
                    </div>
                  </div>
                  <div className='flex flex-col gap-3'>
                    <div className='text-white font-bold mb-1'>
                      Penalty Reset Strategy
                    </div>
                    <PenaltyResetStrategySelect
                      value={block.fields.penaltyResetStrategy}
                      onChange={(value) =>
                        updateField('penaltyResetStrategy', value)
                      }
                    />
                    <div className='w-full text-icon-gray text-sms font-normal'>
                      {selectedPenaltyResetOption?.description ??
                        'Defines when penalty counts should be reset.'}
                    </div>
                  </div>
                </>
              )}
              <label htmlFor='showItemList'>
                <RHFCheckbox<HiddenPictureBlock>
                  label='Show Sidebar'
                  name='showItemList'
                  value={block.fields.showItemList}
                  onChange={(_, checked: boolean): void => {
                    updateField('showItemList', checked);
                  }}
                  description={{
                    enabled: block.fields.maxPenaltyLimit
                      ? 'Enabled: Show a sidebar list displaying the number of penalty clicks incurred and the max penalty limit. No items are displayed.'
                      : 'Enabled: Show a sidebar list of hidden items and highlight the found items. When Sequencing is on, only found items will be shown.',
                    disabled: 'Disabled: Sidebar is hidden.',
                  }}
                />
              </label>
              <label htmlFor='showItemList'>
                <RHFCheckbox<HiddenPictureBlock>
                  label='Randomize Pictures'
                  name='randomizePictures'
                  value={block.fields.randomizePictures}
                  onChange={(_, checked: boolean): void => {
                    updateField('randomizePictures', checked);
                  }}
                  description={{
                    enabled:
                      'Enabled: The play order of the pictures is randomized before gameplay.',
                    disabled:
                      'Disabled: The play order of the pictures is fixed according this configuration.',
                  }}
                />
              </label>
            </div>
          </section>

          <hr className='w-full my-5 border border-secondary' />

          <section className='w-full mb-4'>
            <div className='w-full grid grid-cols-3 gap-x-6 gap-y-10'>
              <label htmlFor='backgroundMedia'>
                <BlockMediaEditor<HiddenPictureBlockMedia>
                  width='w-full'
                  blockId={block.id}
                  title='Background Media'
                  field='backgroundMedia'
                  video
                  volumeSelectable
                  loopSelectable
                  scene={EnumsMediaScene.MediaSceneBlockBackground}
                  mediaData={block.fields.backgroundMediaData}
                  media={block.fields.backgroundMedia}
                  extraNotice='Media will display and play in the background during the Game Timer.'
                />
              </label>
              <label htmlFor='introMedia'>
                <BlockMediaEditor<HiddenPictureBlockMedia>
                  width='w-full'
                  blockId={block.id}
                  title='Intro Media'
                  field='introMedia'
                  video
                  volumeSelectable
                  scene={EnumsMediaScene.MediaSceneBlockMedia}
                  mediaData={block.fields.introMediaData}
                  media={block.fields.introMedia}
                  extraNotice='Media will display to the audience before the block starts.'
                />
              </label>
              <label htmlFor='outroMedia'>
                <BlockMediaEditor<HiddenPictureBlockMedia>
                  width='w-full'
                  blockId={block.id}
                  title='Outro Media'
                  field='outroMedia'
                  video
                  volumeSelectable
                  scene={EnumsMediaScene.MediaSceneBlockMedia}
                  mediaData={block.fields.outroMediaData}
                  media={block.fields.outroMedia}
                  extraNotice='Media will display to the audience when the answer is revealed.'
                />
              </label>
              <label htmlFor='gameCompletionMedia'>
                <BlockMediaEditor<HiddenPictureBlockMedia>
                  width='w-full'
                  blockId={block.id}
                  title='Completion Animation'
                  field='gameCompletionMedia'
                  audio={false}
                  image={false}
                  video={true}
                  scene={EnumsMediaScene.MediaSceneBlockMedia}
                  mediaData={block.fields.gameCompletionMediaData}
                  media={block.fields.gameCompletionMedia}
                  extraNotice='Media will display if the team completes all hidden pictures before the end of the timer.'
                />
              </label>
              {Boolean(block.fields.maxPenaltyLimit) && (
                <label htmlFor='penaltiesFailureMedia'>
                  <BlockMediaEditor<HiddenPictureBlockMedia>
                    width='w-full'
                    blockId={block.id}
                    title='Penalties Failure Animation'
                    field='penaltiesFailureMedia'
                    audio={false}
                    image={false}
                    video={true}
                    scene={EnumsMediaScene.MediaSceneBlockMedia}
                    mediaData={block.fields.penaltiesFailureMediaData}
                    media={block.fields.penaltiesFailureMedia}
                    extraNotice='Media will display if the team hits the max penalty limit.'
                  />
                </label>
              )}
            </div>
          </section>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
