import { type UID } from 'agora-rtc-sdk-ng';
import cloneDeep from 'lodash/cloneDeep';
import { type ReactNode, useEffect, useState } from 'react';

import { type DtoTTSRenderRequest } from '@lp-lib/api-service-client/public';
import { type Media, type MediaData, VolumeLevel } from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useIsController } from '../../hooks/useMyInstance';
import {
  type TaskQueue,
  useStatsAwareTaskQueue,
  useTaskQueue,
} from '../../hooks/useTaskQueue';
import { DefaultLanguageOption } from '../../i18n/language-options';
import { getLogger } from '../../logger/logger';
import { apiService } from '../../services/api-service';
import {
  CustomRTCService,
  type IRTCService,
  WebRTCUtils,
} from '../../services/webrtc';
import { Chan } from '../../utils/Chan';
import { assertDefinedFatal, once } from '../../utils/common';
import { createProvider } from '../../utils/createProvider';
import { MediaUtils } from '../../utils/media';
import { UnplayableBytes } from '../../utils/unplayable';
import { useGameHostingController } from '../Game/GameHostingProvider';
import { useOndGameState, useReceivedIsLiveGamePlay } from '../Game/hooks';
import {
  useParticipantFlag,
  useParticipantsAsArray,
  useParticipantsFlags,
} from '../Player';
import { useMyI18nSettings } from '../Settings/useMyI18nSettings';
import { useSiteI18nSettings } from '../Settings/useSiteI18nSettings';
import { useAudioEnabled, useMyClientId } from '../Venue';
import {
  type TrackId,
  TrackInitConfigBuilder,
  VideoMixer,
} from '../VideoMixer';
import {
  createTrackEndAwaiter,
  createTrackRemoveAwaiter,
  createTrackStartAwaiter,
} from '../VideoMixer/createTrackAwaiters';
import { useJoinRTCService, useTriggerWebRTCJoinFailedModal } from '../WebRTC';
import { makeLocalAudioOnlyVideoMixer } from './makeLocalAudioOnlyVideoMixer';
import { type SubtitlesManager } from './SubtitlesManager';
import { useGlobalSubtitlesManager } from './SubtitlesManagerProvider';
import { extractVariables, type TemplateRenderer } from './VariableRegistry';
import { type VoiceOverRegistryPlan } from './VoiceOverRegistryPlan';

function agoraUIDForLocale(
  venueId: string,
  controllerClientId: string,
  locale: string
): string {
  const name = `vid:${venueId}:cid:${controllerClientId}:localized-vo:${locale}`;
  return WebRTCUtils.AssertAgoraUIDLength(name);
}

function agoraChannelForVenue(venueId: string): string {
  const channel = `v:${venueId}:localized-vo`;
  return WebRTCUtils.AssertAgoraChannelLength(channel);
}

type LVOChannelCtrlMap = Map<LVOChannelCtrl['locale'], LVOChannelCtrl>;

const { Provider: LVRTCPublisherProvider } = createProvider<LVOChannelCtrlMap>(
  'LVRTCPublisherProvider'
);

const globalLVOChannelCtrls: { current: LVOChannelCtrlMap } = {
  current: new Map(),
};

function getGlobalLVOChannelCtrls() {
  return globalLVOChannelCtrls.current;
}

const globalLVOLocalCtrl: { current: LVOLocalCtrl | null } = {
  current: null,
};

function getGlobalLVOLocalCtrl() {
  return globalLVOLocalCtrl.current;
}

export function LocalizedVoiceoversRTCPublisherProvider(props: {
  venueId: string;
  children?: ReactNode;
  readyToJoin: boolean;
}) {
  const [log] = useState(() =>
    getLogger().scoped('localized-voiceovers-publisher')
  );
  const isController = useIsController();
  const isLive = useReceivedIsLiveGamePlay();
  const myClientId = useMyClientId();

  const pFlags = useParticipantsFlags();
  const participants = useParticipantsAsArray({
    filters: ['status:connected'],
  });

  const { data: siteI18n } = useSiteI18nSettings();
  const subs = useGlobalSubtitlesManager();
  const ondGameState = useOndGameState();

  // Synchronize ondGameState (playing/paused etc) with the voiceover
  // videomixers.
  useEffect(() => {
    if (ondGameState === 'running') {
      globalLVOChannelCtrls.current.forEach((ctrl) => ctrl.resume());
      globalLVOLocalCtrl.current?.resume();
    } else if (ondGameState === 'paused') {
      globalLVOChannelCtrls.current.forEach((ctrl) => ctrl.pause());
      globalLVOLocalCtrl.current?.pause();
    } else if (ondGameState === 'ended' || !ondGameState) {
      globalLVOChannelCtrls.current.forEach((ctrl) => ctrl.reset());
      globalLVOLocalCtrl.current?.reset();
    }
  }, [ondGameState]);

  // Create and destroy rtc-specific locale controllers as needed.
  useEffect(() => {
    // Only the controller during an OND game needs to create and destroy these
    // ctrls/channels.
    if (!isController || isLive === null || isLive === true || !siteI18n)
      return;

    // Build unique locales, but always include the default language (English)
    // because the subtitle system needs a base of english for translation.
    const locales = new Set<string>([DefaultLanguageOption.value]);
    for (const p of participants) {
      const flags = pFlags[p.clientId];
      // Only include the user's locale if we support it for voiceovers.
      if (flags?.voiceOverLocale && siteI18n[flags.voiceOverLocale])
        locales.add(flags.voiceOverLocale);
    }

    // Create a new ctrl for each locale if needed
    let dirty = false;
    for (const locale of locales) {
      const existing = globalLVOChannelCtrls.current.get(locale);
      if (existing) continue;

      log.info(`creating new LVOChannelCtrl for locale ${locale}`);

      const ctrl = new LVOChannelCtrl(props.venueId, myClientId, locale, subs);
      globalLVOChannelCtrls.current.set(locale, ctrl);
      dirty = true;
    }

    // NOTE: we keep all ctrls once they have been created because someone could
    // have briefly disconnected and is coming back. We don't want to destroy
    // and recreate the rtc connection so quickly.

    if (dirty) {
      log.info('setting globalLVOChannelCtrls.current');
      globalLVOChannelCtrls.current = new Map(globalLVOChannelCtrls.current);
    }
  }, [
    isController,
    isLive,
    log,
    myClientId,
    pFlags,
    participants,
    props.venueId,
    siteI18n,
    subs,
  ]);

  const myLocale = useParticipantFlag(useMyClientId(), 'voiceOverLocale');

  // Create a new local ctrl if needed, based on your currently selected locale.
  useEffect(() => {
    if (globalLVOLocalCtrl.current) {
      log.info('destroying existing globalLVOLocalCtrl');
      globalLVOLocalCtrl.current.destroy();
      globalLVOLocalCtrl.current = null;
    }

    if (myLocale) {
      log.info('creating globalLVOLocalCtrl');
      globalLVOLocalCtrl.current = new LVOLocalCtrl(
        props.venueId,
        myLocale,
        subs
      );
    }
  }, [log, myLocale, props.venueId, subs]);

  // Destroy everything on unmount
  useEffect(() => {
    return () => {
      log.info('destroying all globalLVO* resources');
      for (const [, ctrl] of globalLVOChannelCtrls.current) {
        ctrl.destroy();
      }
      globalLVOChannelCtrls.current.clear();
      globalLVOLocalCtrl.current?.destroy();
      globalLVOLocalCtrl.current = null;
    };
  }, [log]);

  const queue = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-rtc-localized-join-ms',
  });

  const joiners = [];

  if (props.readyToJoin) {
    for (const [, ctrl] of globalLVOChannelCtrls.current) {
      joiners.push(
        <RTCJoiner
          key={ctrl.locale}
          rtc={ctrl.rtc}
          channel={ctrl.channel}
          queue={queue}
          role='host'
        >
          {(joined) => <AudioPublisher joined={joined} rtc={ctrl.rtc} />}
        </RTCJoiner>
      );
    }
  }

  return (
    <LVRTCPublisherProvider value={globalLVOChannelCtrls.current}>
      {joiners}
      {props.children}
    </LVRTCPublisherProvider>
  );
}

function AudioPublisher(props: { joined: boolean; rtc: IRTCService }) {
  useEffect(() => {
    if (!props.joined) return;
    props.rtc.publishAudio();
  }, [props.joined, props.rtc]);

  return null;
}

function RTCJoiner(props: {
  rtc: CustomRTCService;
  channel: string;
  queue: TaskQueue;
  role: 'audience' | 'host';
  children: (joined: boolean) => ReactNode;
}) {
  const { rtc, channel } = props;

  const handleJoinFailure = useTriggerWebRTCJoinFailedModal();

  const joined = useJoinRTCService(rtc, channel, props.role, props.queue, {
    subscribeEvents: true,
    handleFailure: useLiveCallback(async (err) => {
      const log = getLogger().scoped('localized-voiceovers-rtc-joiner');
      log.error('Failed to join localized voiceover channel', err, { channel });
      await handleJoinFailure();
    }),
  });

  return <>{props.children?.(joined)}</>;
}

/** groups a local videomixer + subtitlesmanager + locale. */
class LVOLocalCtrl {
  readonly localVM = makeLocalAudioOnlyVideoMixer();
  readonly vm = this.localVM[0];
  private log = getLogger().scoped('localized-voiceovers-local-ctrl');

  constructor(
    public readonly venueId: string,
    public readonly locale: string,
    public readonly subs: SubtitlesManager
  ) {
    this.resume();
  }

  async pause() {
    this.log.info('pausing');
    this.vm.pause();
  }

  async resume() {
    this.log.info('playing');
    this.vm.play();
  }

  async reset() {
    this.log.info('resetting');
    this.vm.removeAllTracks();
  }

  destroy() {
    this.log.info('destroying');
    this.vm.destroy();
  }
}

/** groups a videomixer + rtcservice instance + subtitlesmanager + locale. */
class LVOChannelCtrl {
  readonly rtcUid;
  readonly channel;
  readonly vm = new VideoMixer(
    {
      drawFps: 60,
      renderWidth: 0,
      renderHeight: 0,
      audioRenderingOnly: true,
    },
    false,
    'raf-accumulated'
  );
  readonly rtc;
  private log = getLogger().scoped('localized-voiceovers-channel-ctrl');

  constructor(
    venueId: string,
    controllerClientId: string,
    public locale: string,
    public subs: SubtitlesManager
  ) {
    this.channel = agoraChannelForVenue(venueId);
    this.rtcUid = agoraUIDForLocale(venueId, controllerClientId, locale);
    this.resume();

    this.rtc = new CustomRTCService({
      name: `localized-vo:${locale}`,
      uid: this.rtcUid,
      audioEncoderConfig: 'music_standard',
      useDualStream: false,
    });

    this.rtc.switchAudio(this.vm.getOutputMediaStream().getAudioTracks()[0]);
  }

  async pause() {
    this.log.info('pausing');
    this.vm.pause();
  }

  async resume() {
    this.log.info('playing');
    this.vm.play();
  }

  async reset() {
    this.log.info('resetting');
    this.vm.removeAllTracks();
  }

  destroy() {
    this.log.info('destroying');
    this.vm.destroy();
  }
}

/** groups an rtc instance + locale + channel. */
class LocalizedRTC {
  constructor(
    public readonly rtc: CustomRTCService,
    public readonly locale: string,
    public readonly channel: string
  ) {}
}

/**
 * The provider that manages joining the specific channel, subs to the
 * correct locale "user", and plays audio.
 */
export function LocalizedVoiceoverPlayerProvider(props: {
  venueId: string;
  readyToJoin: boolean;
  children?: ReactNode;
}) {
  const [log] = useState(() =>
    getLogger().scoped('localized-voiceovers-player')
  );
  const i18n = useMyI18nSettings();
  const { data: siteI18n } = useSiteI18nSettings();
  // Only use the user's locale if we support it for voiceovers. Otherwise play
  // them the default (english). There is a possibility that the user could have
  // selected a language, and then we changed or removed it from the list. This
  // would result in the user waiting for an agora publisher that will never
  // exist.
  const locale =
    i18n.i18nSettings?.value?.voiceOverLocale &&
    siteI18n &&
    siteI18n[i18n.i18nSettings.value.voiceOverLocale]
      ? i18n.i18nSettings?.value?.voiceOverLocale
      : DefaultLanguageOption.value;

  const controller = useGameHostingController();
  const queue = useTaskQueue({ shouldProcess: true });
  const channel = agoraChannelForVenue(props.venueId);
  const sourceUid =
    locale && controller
      ? agoraUIDForLocale(props.venueId, controller.clientId, locale)
      : null;
  const isLive = useReceivedIsLiveGamePlay();

  const myClientId = useMyClientId();
  const audioEnabled = useAudioEnabled();

  const [lrtc, setLrtc] = useState<null | LocalizedRTC>(null);

  useEffect(() => {
    if (!locale || !sourceUid || lrtc?.locale === locale) return;

    // Wait until we know the isLive flag is resolved before creating the
    // player. We don't want to subscribe to the service if it's a live 1.0
    // game.
    if (isLive === null || isLive === true) return;

    log.info(`creating new player ${locale}`, { sourceUid });

    const nextRtc = new CustomRTCService({
      name: `localized-vo:${locale}`,
      uid: `${myClientId}`,
      audioEncoderConfig: 'music_standard',
      useDualStream: false,
    });

    const next = new LocalizedRTC(nextRtc, locale, channel);

    setLrtc(next);
  }, [
    channel,
    isLive,
    locale,
    log,
    myClientId,
    props.venueId,
    lrtc?.locale,
    sourceUid,
  ]);

  return (
    <>
      {
        // only join and play the audio if this is an ond game (not live 1.0)
        isLive === false && lrtc && props.readyToJoin && (
          <RTCJoiner
            key={locale}
            rtc={lrtc.rtc}
            channel={channel}
            queue={queue}
            role={'audience'}
          >
            {(joined) =>
              sourceUid &&
              audioEnabled && (
                <AudioPlayer
                  memberId={sourceUid}
                  joined={joined}
                  rtc={lrtc.rtc}
                />
              )
            }
          </RTCJoiner>
        )
      }
      {props.children}
    </>
  );
}

/**
 * Manages play/pausing audio from agora
 */
function AudioPlayer(props: {
  memberId: string;
  joined: boolean;
  rtc: CustomRTCService;
}) {
  const { memberId, rtc: rtcService } = props;

  useEffect(() => {
    if (!props.joined) return;

    async function onPublished(uid: UID, mediaType: 'audio' | 'video') {
      if (uid !== memberId || !rtcService) return;

      if (mediaType === 'audio') {
        rtcService.playAudio(uid);
      }
    }

    async function onUnpublished(uid: UID, mediaType: 'audio' | 'video') {
      if (uid !== memberId || !rtcService) return;

      if (mediaType === 'audio') {
        rtcService.stopAudio(uid);
      }
    }

    if (!memberId || !rtcService) return;

    const [audioTrack] = rtcService.getTracksByUid(memberId);
    if (audioTrack) onPublished(memberId, 'audio');

    const offs: (() => void)[] = [];
    offs.push(rtcService.on('remote-user-published', onPublished));
    offs.push(rtcService.on('remote-user-unpublished', onUnpublished));

    return () => {
      offs.forEach((dispose) => dispose());
    };
  }, [memberId, props.joined, rtcService]);

  useEffect(() => {
    return () => {
      if (!memberId || !rtcService) return;
      rtcService.stopAudio(memberId);
      rtcService.stopVideo(memberId);
    };
  }, [memberId, rtcService]);
  return null;
}

/**
 * Take an untranslated voice over request, translate it into all the current
 * locales in the venue, and request it to be rendered to warm up the backend
 * caches. The response is not used.
 */
export async function lvoCacheWarm(entry: Nullable<DtoTTSRenderRequest>) {
  if (!entry) return;
  const ctrls = getGlobalLVOChannelCtrls();

  const actions = [];

  for (const [, ctrl] of ctrls) {
    const action = (async () => {
      const treq = await TranslatedDtoTTSRenderRequest.From(ctrl.locale, entry);
      await RenderedDtoTTSRenderRequest.Bytes(treq, true);
    })();

    actions.push(action);
  }

  await Promise.all(actions);
}

// While we don't really need "plans" anymore, it's a lot of work to remove them
// all from the playback generation (and elsewhere).
export async function lvoTTSRequestFromPlan(
  plan: VoiceOverRegistryPlan,
  vr: TemplateRenderer
) {
  // We're going to sort, so make a copy.
  const ttsEntries = plan.entries.slice(0);
  // Backwards compat: Sort by number of %variable% in the script so we can pick
  // the most complex from the plan.
  ttsEntries.sort((a, b) => {
    const aVars = extractVariables(a.script).length ?? 0;
    const bVars = extractVariables(b.script).length ?? 0;
    return aVars - bVars;
  });

  const entry = ttsEntries.at(-1);
  if (!entry) return;
  return lvoResolveTTSRequest(entry, vr);
}

/**
 * Call `render` on a given TemplateRenderer using the request's script. Returns
 * null if the script had variables not resolvable by the TemplateRenderer.
 */
export async function lvoResolveTTSRequest(
  req: Nullable<DtoTTSRenderRequest>,
  vr: TemplateRenderer
) {
  if (!req || !req.script) return null;

  const cloned = cloneDeep(req);
  const resolved = await vr.render(cloned.script);

  // If the script could not be resolved, do not try to render it because it
  // will be obviously broken and it's just a waste to call tts on it.
  if (!resolved.resolved) return null;

  cloned.script = resolved.script;

  return cloned;
}

type LVOPlayInfo = {
  locale: string;
  media: Media;
  approximateDurationMs: number;
  trackEnded: Promise<void>;
  trackStarted: Promise<void>;
  trackRemoved: Promise<void>;
};

/**
 * Play a voice over locally, taking into account the current user's current
 * locale. See @see LVOBroadcastPlayer for the opposite version.
 */
export class LVOLocalPlayer {
  private trackId: Nullable<TrackId> = null;

  constructor(
    private req: Nullable<DtoTTSRenderRequest>,
    private getLVOCtrl = getGlobalLVOLocalCtrl
  ) {}

  async play(options?: { delayStartMs?: number }) {
    const ctrl = this.getLVOCtrl();
    if (!this.req || !ctrl) return;

    const treq = await TranslatedDtoTTSRenderRequest.From(
      ctrl.locale,
      this.req
    );
    const rreq = await RenderedDtoTTSRenderRequest.From(treq, false);

    const format = MediaUtils.PickMediaFormat(rreq?.media);
    if (!format) return;

    const cfg = new TrackInitConfigBuilder()
      .setTimelineTimeStartMs(
        (ctrl.vm.playheadMs ?? 0) + (options?.delayStartMs ?? 0)
      )
      .setDurationMs(format.length)
      .build();

    this.trackId = ctrl.vm.pushTrack(rreq.unplayable.media, cfg);
    const startAwaiter = createTrackStartAwaiter(ctrl.vm, this.trackId);
    const endAwaiter = createTrackEndAwaiter(ctrl.vm, this.trackId);
    const removedAwaiter = createTrackRemoveAwaiter(ctrl.vm, this.trackId);

    const ret: LVOPlayInfo = {
      locale: ctrl.locale,
      media: rreq.media,
      approximateDurationMs: format.length,
      trackEnded: endAwaiter,
      trackStarted: startAwaiter,
      trackRemoved: removedAwaiter,
    };

    // Purposefully not awaiting here. Pass in the original, untranslated
    // script. Subtitles always need english.
    ctrl.subs.notify('local', this.req.script, ret);

    return ret;
  }

  stop() {
    if (!this.trackId) return;
    const ctrl = this.getLVOCtrl();
    if (!ctrl) return;
    ctrl.vm.removeTrack(this.trackId);
  }
}

/**
 * Play a voice over for all users in the venue, taking into account the current
 * locales present and translating appropriately. You may wish to call
 * `lvoCacheWarm` if you have time before the playback needs to start. Nothing
 * happens (loading, etc) until you call `.play()`. See @see LVOLocalPlayer for
 * the opposite version.
 */
export class LVOBroadcastPlayer {
  private trackIds: Map<LVOChannelCtrl, TrackId> = new Map();

  constructor(
    private req: Nullable<DtoTTSRenderRequest>,
    private getLVOCtrls = getGlobalLVOChannelCtrls
  ) {}

  async play(options?: { delayStartMs?: number }) {
    if (!this.req) {
      return {
        tracksEnded: Promise.resolve(),
        infos: [],
      };
    }

    const ctrls = this.getLVOCtrls();

    const actions = [];

    for (const [, ctrl] of ctrls) {
      const action = (async () => {
        if (!this.req) return null;

        const treq = await TranslatedDtoTTSRenderRequest.From(
          ctrl.locale,
          this.req
        );
        const rreq = await RenderedDtoTTSRenderRequest.From(treq, false);
        const format = MediaUtils.PickMediaFormat(rreq?.media);
        if (!format) return null;

        const cfg = new TrackInitConfigBuilder()
          .setTimelineTimeStartMs(
            (ctrl.vm.playheadMs ?? 0) + (options?.delayStartMs ?? 0)
          )
          .setDurationMs(format.length)
          .build();

        const trackId = ctrl.vm.pushTrack(rreq.unplayable.media, cfg);
        const startAwaiter = createTrackStartAwaiter(ctrl.vm, trackId);
        const endAwaiter = createTrackEndAwaiter(ctrl.vm, trackId);
        const removedAwaiter = createTrackRemoveAwaiter(ctrl.vm, trackId);

        this.trackIds.set(ctrl, trackId);

        const ret: LVOPlayInfo = {
          locale: ctrl.locale,
          media: rreq.media,
          approximateDurationMs: format.length,
          trackEnded: endAwaiter,
          trackStarted: startAwaiter,
          trackRemoved: removedAwaiter,
        };

        // Notify the subtitles manager, but only for English. Translation
        // happens via a client request, not here.
        if (ctrl.locale === DefaultLanguageOption.value) {
          // Purposefully not awaiting here. Pass in the original, untranslated
          // script.
          ctrl.subs.notify('broadcast', this.req.script, ret);
        }

        return ret;
      })();

      actions.push(action);
    }

    const infos = await Promise.all(actions);

    const endeds = [];

    for (const info of infos) {
      if (!info) continue;
      endeds.push(info.trackEnded);
    }

    return {
      tracksEnded: Promise.all(endeds).then(() => void 0),
      infos,
    };
  }

  stop() {
    for (const [ctrl, trackId] of this.trackIds) {
      ctrl.vm.removeTrack(trackId);
    }
  }
}

/** Represents a render request that has been transformed into a specific,
 * translated version (e.g. Spanish). */
class TranslatedDtoTTSRenderRequest {
  static async From(locale: string, request: DtoTTSRenderRequest) {
    const translated = await apiService.translation.translate({
      text: request.script,
      sourceLanguageCode: DefaultLanguageOption.value,
      targetLanguageCode: locale,
    });

    const cloned = cloneDeep(request);
    cloned.script = translated.data.text;
    cloned.locale = locale;

    return new TranslatedDtoTTSRenderRequest(locale, cloned);
  }

  constructor(
    public readonly locale: string,
    public readonly request: DtoTTSRenderRequest
  ) {}
}

/** Represents a render request that has been transformed into something
 * playable via /tts/render */
class RenderedDtoTTSRenderRequest {
  static concurrencyChannels = {
    cacheWarm: new Chan<true>({ multicast: false }),
    play: new Chan<true>({ multicast: false }),
  };

  static async Bytes(treq: TranslatedDtoTTSRenderRequest, cacheWarm: boolean) {
    const channel = cacheWarm
      ? this.concurrencyChannels.cacheWarm
      : this.concurrencyChannels.play;
    try {
      await channel.take();
      return (await apiService.tts.render(treq.request)).data;
    } finally {
      channel.put(true);
    }
  }

  static async From(treq: TranslatedDtoTTSRenderRequest, cacheWarm: boolean) {
    const bytes = await RenderedDtoTTSRenderRequest.Bytes(treq, cacheWarm);

    const unplayable = new UnplayableBytes(bytes);
    unplayable.intoPlayable();
    await once(unplayable.media, 'loadedmetadata');
    const lengthSec = unplayable.media.duration;
    const media = MediaUtils.IntoFakeAudioMediaFromBytes(
      unplayable.media.src,
      bytes,
      lengthSec * 1000
    );
    assertDefinedFatal(media);

    return new RenderedDtoTTSRenderRequest(
      treq.locale,
      treq.request,
      media,
      {
        id: media.id,
        volumeLevel: VolumeLevel.Full,
      },
      unplayable
    );
  }

  constructor(
    public readonly locale: string,
    public readonly request: DtoTTSRenderRequest,
    public readonly media: Media,
    public readonly mediaData: MediaData,
    public readonly unplayable: UnplayableBytes
  ) {}
}

// prime concurrency channels
for (let i = 0; i < 10; i++) {
  RenderedDtoTTSRenderRequest.concurrencyChannels.cacheWarm.put(true);
  RenderedDtoTTSRenderRequest.concurrencyChannels.play.put(true);
}
