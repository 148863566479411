import { CanvasImageSourceFrameBuffer } from '../VideoFrameProcessing';
import { type IVideoMixerTrack } from './IVideoMixerTrack';
import {
  type InjectedProcessors,
  ProcessorRegistry,
} from './ProcessorRegistry';
import {
  type AudioEventDesc,
  type TrackId,
  type TrackInitConfig,
} from './types';
import { VideoMixerTrack } from './VideoMixerTrack';

// const log = logger.scoped('video-mixer-track');

export class VideoMixerImageTrack implements IVideoMixerTrack {
  readonly vfbuffer: CanvasImageSourceFrameBuffer;
  private _playing = false;
  private processors: ProcessorRegistry;

  renderCount = 0;

  constructor(
    public readonly id: TrackId,
    public readonly image: HTMLImageElement,
    public readonly config: TrackInitConfig,
    injectedProcessors?: Partial<InjectedProcessors>
  ) {
    this.vfbuffer = new CanvasImageSourceFrameBuffer(image);
    this.processors = new ProcessorRegistry(injectedProcessors);
  }

  sourceDescription(): string {
    return `ImageTrack(${this.id}, ${
      this.image.src.indexOf('base64') > -1 ? 'base64' : this.image.src
    })`;
  }

  acceptPatch(patch: Partial<TrackInitConfig>): boolean {
    return VideoMixerTrack.prototype.acceptPatch.call(this, patch);
  }

  getAudioSource(): null {
    return null;
  }

  get playing(): boolean {
    return this._playing;
  }

  play(): void {
    this._playing = true;
  }

  pause(): void {
    this._playing = false;
  }

  seekToMs(_localTimeMs: number): void {
    // noop
  }

  async destroy(): Promise<void> {
    await this.processors.destroy();
  }

  get videoDurationMs(): number {
    return this.config.durationMs;
  }

  computeActiveTimelineTimeMs(): readonly [number, number] {
    return VideoMixerTrack.prototype.computeActiveTimelineTimeMs.call(this);
  }

  computeDesync(
    _timelineTimeMs: number
  ): { expectedLocalTimeMs: number; actualLocalTimeMs: number } | null {
    // An image cannot be desynced
    return null;
  }

  isSeekableTo(_localTimeMs: number): boolean {
    // Image is always "seekable"...
    return true;
  }

  nextAudioEvents(
    _prevTimelineTimeMs: number,
    _nextTimelineTimeMs: number,
    _audioCtxTimeSec: number
  ): AudioEventDesc[] {
    return [];
  }

  draw(timelineTimeMs: number, target: CanvasRenderingContext2D): number {
    return VideoMixerTrack.prototype.draw.call(this, timelineTimeMs, target);
  }
}
