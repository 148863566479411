import { asFBReference } from '@lp-lib/firebase-typesafe';

import { Emitter } from '../../utils/emitter';
import type { FirebaseService } from '../Firebase';
import {
  type FBMsgPassStorage,
  recv,
  send,
} from '../Firebase/FirebaseMessagePassing';

type SubtitlesMessage = { kind: 'script-now'; script: string };

export class SubtitlesManager {
  private emitter = new Emitter<{
    'script-now': (script: string) => void;
  }>();

  msgRef;

  constructor(venueId: string, svc: FirebaseService) {
    this.msgRef = asFBReference<FBMsgPassStorage>(
      svc.prefixedSafeRef(`subtitles-manager/${venueId}`)
    );
  }

  on = this.emitter.on.bind(this.emitter);

  recvAborter = new AbortController();

  listen() {
    this.recvAborter.abort();
    this.recvAborter = new AbortController();

    recv(
      this.msgRef,
      async (msg: SubtitlesMessage) => {
        if (msg.kind === 'script-now') {
          this.emitter.emit('script-now', msg.script);
        }
      },
      {
        signal: this.recvAborter.signal,
      }
    );
  }

  /**
   * This is best called from a single client, such as the controller. Calling
   * from multiple during the wrong lifecycle could result in a disconnecting
   * user removing all subtitles for all users in the venue.
   */
  async cleanupMessages() {
    await this.msgRef.remove();
  }

  /**
   * - `local`: this is for the current user only
   * - `broadcast`: this is for all users in the venue
   *
   * It is expected that `local` is from a local-only voiceover, such as the H2H
   * block. `broadcast` is likely only ever specified on the controller so that
   * all users receive the subtitles.
   */
  async notify(
    kind: 'local' | 'broadcast',
    script: string,
    info: { trackStarted: Promise<void> }
  ) {
    await info.trackStarted;

    if (kind === 'broadcast') {
      await send<SubtitlesMessage>(
        this.msgRef,
        {
          kind: 'script-now',
          script: script,
        },
        {
          cleanupAfterMs: 30000,
        }
      );
    } else {
      this.emitter.emit('script-now', script);
    }
  }

  destroy() {
    this.emitter.clear();
  }
}
