import {
  DndContext,
  type DragEndEvent,
  type DragMoveEvent,
  useDraggable,
} from '@dnd-kit/core';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type ConnectDragSource } from 'react-dnd';
import {
  Controller,
  FormProvider,
  type Path,
  useFieldArray,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { usePopperTooltip } from 'react-popper-tooltip';
import Select, { type SingleValue } from 'react-select';
import { useKey } from 'react-use';
import useMeasure from 'react-use-measure';

import {
  EnumsHiddenPictureAsymmetricPinDropAudibility,
  EnumsHiddenPictureAsymmetricPinDropVisibility,
  EnumsMediaScene,
} from '@lp-lib/api-service-client/public';
import {
  type HiddenPicture,
  HotSpotShape,
  type HotSpotShapeData,
  type HotSpotV2,
} from '@lp-lib/game';
import {
  type Media,
  type MediaData,
  MediaType,
  VolumeLevel,
} from '@lp-lib/media';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { assertExhaustive, uuidv4 } from '../../../../utils/common';
import { MediaPickPriorityHD, MediaUtils } from '../../../../utils/media';
import { buildReactSelectStyles } from '../../../../utils/react-select';
import { ResizeObserver } from '../../../../utils/ResizeObserver';
import { Menu, MenuItem } from '../../../common/ActionMenu';
import { DragDropList } from '../../../common/DragDrop';
import { type Option } from '../../../common/Utilities';
import {
  type ConfirmCancelModalConfigurator,
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
  useCancelConfirmModalStateRoot,
} from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { CopyIcon } from '../../../icons/CopyIcon';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { MenuIcon } from '../../../icons/MenuIcon';
import { Loading } from '../../../Loading';
import { MediaEditor } from '../../../MediaUploader/MediaEditor';
import { MediaUploader } from '../../../MediaUploader/MediaUploader';
import { VolumeSelect } from '../../../MediaUploader/VolumeSelect';
import { SwitcherControlled } from '../../../Switcher';
import { PointsInput } from '../Common/Editor/PointsUtilities';
import { type AdditionalSettings, type AsymmetricSettings } from './types';
import { ASPECT_RATIO, HiddenPictureUtils, MIN_BOX_RATIO } from './utils';

const HOT_SPOT_EDITOR_HEIGHT_PX = 88;
const HOT_SPOT_EDITOR_GAP_PX = 16;

function Switcher(props: {
  name: Path<HiddenPictureFormData>;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  description: {
    enabled: string;
    disabled: string;
  };
}): JSX.Element {
  const description = useMemo(() => {
    if (!props.description) return;
    return props.value ? props.description.enabled : props.description.disabled;
  }, [props.value, props.description]);

  return (
    <label htmlFor='everyoneClicks'>
      <div className='w-full flex flex-col'>
        <div className='flex items-center justify-between'>
          <span className='text-white font-bold'>{props.label}</span>
          <SwitcherControlled
            name={props.name}
            className=''
            checked={props.value}
            onChange={props.onChange}
          />
        </div>
        {description && (
          <div className='text-icon-gray text-sm font-medium mt-2'>
            {description}
          </div>
        )}
      </div>
    </label>
  );
}

function IncorrectAnswerPenaltyInput(props: {
  value: number;
  onChange: (value: number) => void;
}): JSX.Element {
  return (
    <div className='flex gap-3'>
      <div>
        <div className='text-white font-bold mb-1'>
          Incorrect Answer Penalty
        </div>
        <div className='w-full text-icon-gray text-sm font-normal'>
          Penalty for clicking a non-hotspot. If left at 0 there is no penalty.
        </div>
      </div>
      <div className='flex-none'>
        <PointsInput
          className='w-25 text-center'
          defaultValue={props.value}
          onChange={props.onChange}
          min={0}
          renderValue={(value) => `${value} pts`}
        />
      </div>
    </div>
  );
}

type Tool = HiddenPicture['tool'];
const toolOptions: Option<Tool>[] = [
  {
    label: 'No Tool',
    value: 'none',
  },
  {
    label: 'Magnifier',
    value: 'magnifier',
  },
  {
    label: 'Flashlight',
    value: 'flashlight',
  },
  {
    label: 'Unblur',
    value: 'unblur',
  },
];

function ToolSelector(props: {
  value: Tool;
  onChange: (val: Tool) => void;
}): JSX.Element {
  const { setValue } = useFormContext<HiddenPictureFormData>();
  const mainMedia = useWatch<HiddenPictureFormData, 'mainMedia'>({
    name: 'mainMedia',
  });

  useEffect(() => {
    if (props.value !== 'none' && mainMedia?.type !== MediaType.Image) {
      setValue('tool', 'none');
    }
  }, [props.value, mainMedia?.type, setValue]);

  const disabled = mainMedia?.type !== MediaType.Image;
  const value =
    toolOptions.find((o) => o.value === props.value) ?? toolOptions[0];
  const styles = useMemo(() => buildReactSelectStyles<Option<Tool>>(), []);

  return (
    <div className='flex gap-3'>
      <div>
        <div className='text-white font-bold mb-1'>Tool</div>
        <div className='w-full text-icon-gray text-sm font-normal'>
          Define a tool available to a single user to assist in finding the
          hidden items.
        </div>
      </div>
      <div className='flex-none'>
        <Select<Option<Tool>, false>
          options={toolOptions}
          value={value}
          styles={styles}
          classNamePrefix='select-box-v2'
          isSearchable={false}
          onChange={(v) => props.onChange(v === null ? 'none' : v.value)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
}

function HotSpotEditor(props: {
  initialData: HotSpotV2;
  onDelete: () => void;
  onSelect: () => void;
  onUpdate: (updated: HotSpotV2) => void;
  onDuplicate: () => void;
  index: number;
  drag?: ConnectDragSource;
  selected?: boolean;
  sequenced?: boolean;
}): JSX.Element {
  const {
    initialData,
    onDelete,
    onDuplicate,
    index,
    drag,
    selected,
    sequenced,
  } = props;
  return (
    <div
      className='relative w-full min-w-60 group flex items-start gap-1 cursor-pointer'
      style={{ height: HOT_SPOT_EDITOR_HEIGHT_PX }}
      onMouseDown={() => props.onSelect()}
    >
      {sequenced && (
        // this is going to be a bit clumsy at 3 digits on hover, since the width isn't fixed.
        // that's a lot of items though, and the ui will already feel clunky at that point.
        <div className='flex-none font-bold font-mono text-2xs min-w-5 mt-1'>
          <div className='group-hover:hidden'>{index + 1}.</div>
          <div className='hidden group-hover:flex cursor-move' ref={drag}>
            <MenuIcon />
          </div>
        </div>
      )}
      <div
        className={`
          w-full h-full px-4 py-3 
          flex items-center gap-2 
          rounded-md 
          bg-secondary group-hover:bg-secondary-hover
          border ${selected ? 'border-primary' : 'border-transparent'}
        `}
      >
        <div className='w-full h-full'>
          <div className='pb-1 font-bold text-sms'>Item Name</div>
          <input
            key={initialData.id}
            type='text'
            className='field w-full h-10 mb-0'
            placeholder='Enter Name'
            defaultValue={props.initialData.name}
            onBlur={(e) => {
              if (e.target.value === props.initialData.name) return;
              props.onUpdate({ ...initialData, name: e.target.value });
            }}
          />
        </div>
        <div className='h-full'>
          <div className='pb-1 font-bold text-sms'>Points</div>
          <PointsInput
            defaultValue={props.initialData.points}
            onChange={(points) => {
              props.onUpdate({ ...initialData, points });
            }}
            renderValue={(value) => `${value} pts`}
          />
        </div>
        <div className='flex flex-col gap-4'>
          <button
            type='button'
            className={`
              flex-none
              invisible group-hover:visible
              flex items-center justify-center
              text-red-002 transform hover:scale-110
            `}
            onClick={onDelete}
          >
            <DeleteIcon />
          </button>
          <button
            type='button'
            className={`
              flex-none
              invisible group-hover:visible
              flex items-center justify-center
              text-white transform hover:scale-110
            `}
            onClick={onDuplicate}
          >
            <CopyIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

type HandlePos = 'nw' | 'ne' | 'sw' | 'se';

function Handle(props: { id: string; pos: HandlePos }) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${props.id}-${props.pos}`,
    data: {
      type: 'handle',
      pos: props.pos,
    },
  });

  const pos =
    props.pos === 'nw'
      ? '-top-0.5 -left-0.5'
      : props.pos === 'ne'
      ? '-top-0.5 -right-0.5'
      : props.pos === 'sw'
      ? '-bottom-0.5 -left-0.5'
      : '-bottom-0.5 -right-0.5';

  const cursor =
    props.pos === 'nw' || props.pos === 'se'
      ? 'cursor-[nwse-resize]'
      : 'cursor-[nesw-resize]';

  return (
    <div
      ref={setNodeRef}
      className={`absolute bg-white w-1 h-1 z-5 ${cursor} ${pos}`}
      {...attributes}
      {...listeners}
    />
  );
}

type GutterPos = 'n' | 's' | 'w' | 'e';

function Gutter(props: { id: string; pos: GutterPos }) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${props.id}-${props.pos}`,
    data: {
      type: 'gutter',
      pos: props.pos,
    },
  });

  const pos =
    props.pos === 'n'
      ? 'top-0 left-0 right-0 h-1 border-t border-primary'
      : props.pos === 's'
      ? 'bottom-0 left-0 right-0 h-1 border-b border-primary'
      : props.pos === 'w'
      ? 'left-0 top-0 bottom-0 w-1 border-l border-primary'
      : 'right-0 top-0 bottom-0 w-1 border-r border-primary';

  const cursor =
    props.pos === 'n' || props.pos === 's'
      ? 'cursor-[ns-resize]'
      : 'cursor-[ew-resize]';

  return (
    <div
      ref={setNodeRef}
      className={`absolute z-5 ${cursor} ${pos}`}
      {...attributes}
      {...listeners}
    />
  );
}

function HotSpotCircle(props: {
  hotSpot: HotSpotV2;
  index: number;
  containerRect?: { width: number; height: number };
  onSelect: () => void;
  onUpdate: (updated: HotSpotV2) => void;
  onDelete: () => void;
  selected?: boolean;
  ping?: boolean;
  sequenced?: boolean;
}): JSX.Element | null {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.hotSpot.id,
    data: {
      type: 'hotspot',
    },
    disabled: !props.selected,
  });

  const handleDelete = useLiveCallback(() => {
    if (!props.selected) return;
    props.onDelete();
  });

  useKey('Backspace', handleDelete);

  const [resizeTransform, setResizeTransform] = useState<
    { top: number; left: number; boxSize: number } | undefined
  >(undefined);

  if (!props.containerRect) return null;

  const container = {
    maxX: props.containerRect.width,
    maxY: props.containerRect.height,
  };

  const minBoxSize = MIN_BOX_RATIO * container.maxY;

  const hotSpot = HiddenPictureUtils.FromUnitGridHotSpot(
    props.hotSpot,
    container
  );
  const shape = hotSpot.shapeData.circle;
  if (!shape) return null;

  const handleDragMove = (event: DragMoveEvent) => {
    const handlePos: HandlePos = event.active.data.current?.pos;
    const { initial, translated } = event.active.rect.current;
    if (!initial || !translated || !handlePos) return;

    // Calculate the new size based on the handle that's being dragged.
    const size = shape.radius * 2;
    const handleX = shape.left + event.delta.x;
    const handleY = shape.top + event.delta.y;
    const bound = (size: number) => Math.max(minBoxSize, size);

    let newSize: number;
    switch (handlePos) {
      case 'nw':
        newSize = bound(
          Math.max(shape.left + size - handleX, shape.top + size - handleY)
        );
        return setResizeTransform({
          top: shape.top + size - newSize,
          left: shape.left + size - newSize,
          boxSize: newSize,
        });
      case 'ne':
        newSize = bound(
          Math.max(handleX - shape.left, shape.top + size - handleY)
        );
        return setResizeTransform({
          top: shape.top + size - newSize,
          left: shape.left,
          boxSize: newSize,
        });
      case 'sw':
        newSize = bound(
          Math.max(shape.left + size - handleX, handleY - shape.top)
        );
        return setResizeTransform({
          top: shape.top,
          left: shape.left + size - newSize,
          boxSize: newSize,
        });
      case 'se':
        newSize = bound(
          Math.max(handleX + size - shape.left, handleY + size - shape.top)
        );
        return setResizeTransform({
          top: shape.top,
          left: shape.left,
          boxSize: newSize,
        });
      default:
        assertExhaustive(handlePos);
    }
  };

  const handleDragEnd = () => {
    if (!resizeTransform) return;

    let updated = HiddenPictureUtils.ToUnitGridHotSpot(
      {
        ...props.hotSpot,
        shapeData: {
          circle: {
            top: resizeTransform.top,
            left: resizeTransform.left,
            radius: resizeTransform.boxSize / 2,
          },
        },
      },
      container
    );
    updated = HiddenPictureUtils.Bound(updated);
    props.onUpdate(updated);
    setResizeTransform(undefined);
  };

  const fontSize =
    shape.radius < 0.1
      ? 'text-sm'
      : shape.radius < 0.2
      ? 'text-lg'
      : shape.radius < 0.3
      ? 'text-xl'
      : 'text-2xl';

  const boxSize = resizeTransform?.boxSize ?? shape.radius * 2;
  const x = transform?.x ?? 0;
  const y = transform?.y ?? 0;
  const color = props.hotSpot.points < 0 ? 'bg-[#CA2121]' : 'bg-green-400';

  return (
    <DndContext onDragMove={handleDragMove} onDragEnd={handleDragEnd}>
      <div
        ref={setNodeRef}
        className={`
          absolute
          box-content 
          border ${
            props.selected
              ? 'border-primary z-10 cursor-move'
              : 'border-transparent cursor-pointer'
          }
        `}
        style={{
          height: boxSize,
          width: 'auto',
          aspectRatio: '1 / 1',
          top: resizeTransform?.top ?? shape.top,
          left: resizeTransform?.left ?? shape.left,
          transform: `translate3d(${x}px, ${y}px, 0)`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          props.onSelect();
        }}
        {...attributes}
        {...listeners}
      >
        <div
          className={`
            ${color} bg-opacity-50 rounded-full w-full h-full flex items-center justify-center select-none
          `}
        >
          {props.sequenced && (
            <div className={`${fontSize} font-mono font-bold`}>
              {props.index + 1}
            </div>
          )}
        </div>
        {props.ping && (
          <div
            className={`animate-ping-once absolute inset-0 h-full w-full rounded-full ${color}`}
          />
        )}

        {props.selected && (
          <>
            <Handle id={props.hotSpot.id} pos='nw' />
            <Handle id={props.hotSpot.id} pos='ne' />
            <Handle id={props.hotSpot.id} pos='sw' />
            <Handle id={props.hotSpot.id} pos='se' />
          </>
        )}
      </div>
    </DndContext>
  );
}

function HotSpotRectangle(props: {
  hotSpot: HotSpotV2;
  index: number;
  containerRect?: { width: number; height: number };
  onSelect: () => void;
  onUpdate: (updated: HotSpotV2) => void;
  onDelete: () => void;
  selected?: boolean;
  ping?: boolean;
  sequenced?: boolean;
}): JSX.Element | null {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.hotSpot.id,
    data: {
      type: 'hotspot',
    },
    disabled: !props.selected,
  });

  const handleDelete = useLiveCallback(() => {
    if (!props.selected) return;
    props.onDelete();
  });

  useKey('Backspace', handleDelete);

  const [resizeTransform, setResizeTransform] = useState<
    { top: number; left: number; width: number; height: number } | undefined
  >(undefined);

  if (!props.containerRect) return null;

  const container = {
    maxX: props.containerRect.width,
    maxY: props.containerRect.height,
  };

  const hotSpot = HiddenPictureUtils.FromUnitGridHotSpot(
    props.hotSpot,
    container
  );
  const shape = hotSpot.shapeData.rectangle;
  if (!shape) return null;

  const handleDragMove = (event: DragMoveEvent) => {
    const data = event.active.data.current;
    const type = data?.type;
    const { initial, translated } = event.active.rect.current;
    if (!initial || !translated || !type) return;

    if (type === 'handle') {
      const handlePos: HandlePos = data.pos;
      switch (handlePos) {
        case 'nw': {
          const nextWidth = shape.width - event.delta.x;
          const nextHeight = shape.height - event.delta.y;

          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? shape.height : event.delta.y),
            left: shape.left + (nextWidth < 0 ? shape.width : event.delta.x),
            width: Math.abs(nextWidth),
            height: Math.abs(nextHeight),
          });
        }
        case 'ne': {
          const nextWidth = shape.width + event.delta.x;
          const nextHeight = shape.height - event.delta.y;

          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? shape.height : event.delta.y),
            left: shape.left + (nextWidth < 0 ? nextWidth : 0),
            width: Math.abs(nextWidth),
            height: Math.abs(nextHeight),
          });
        }
        case 'sw': {
          const nextWidth = shape.width - event.delta.x;
          const nextHeight = shape.height + event.delta.y;

          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? nextHeight : 0),
            left: shape.left + (nextWidth < 0 ? shape.width : event.delta.x),
            width: Math.abs(nextWidth),
            height: Math.abs(nextHeight),
          });
        }
        case 'se': {
          const nextWidth = shape.width + event.delta.x;
          const nextHeight = shape.height + event.delta.y;

          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? nextHeight : 0),
            left: shape.left + (nextWidth < 0 ? nextWidth : 0),
            width: Math.abs(nextWidth),
            height: Math.abs(nextHeight),
          });
        }
        default:
          assertExhaustive(handlePos);
      }
    } else if (type === 'gutter') {
      const gutterPos: GutterPos = data.pos;
      switch (gutterPos) {
        case 'n': {
          const nextHeight = shape.height - event.delta.y;
          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? shape.height : event.delta.y),
            left: shape.left,
            width: shape.width,
            height: Math.abs(nextHeight),
          });
        }
        case 's': {
          const nextHeight = shape.height + event.delta.y;
          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? nextHeight : 0),
            left: shape.left,
            width: shape.width,
            height: Math.abs(nextHeight),
          });
        }
        case 'w': {
          const nextWidth = shape.width - event.delta.x;
          return setResizeTransform({
            top: shape.top,
            left: shape.left + (nextWidth < 0 ? shape.width : event.delta.x),
            width: Math.abs(nextWidth),
            height: shape.height,
          });
        }
        case 'e': {
          const nextWidth = shape.width + event.delta.x;
          return setResizeTransform({
            top: shape.top,
            left: shape.left + (nextWidth < 0 ? nextWidth : 0),
            width: Math.abs(nextWidth),
            height: shape.height,
          });
        }
      }
    }
  };

  const handleDragEnd = () => {
    if (!resizeTransform) return;

    let updated = HiddenPictureUtils.ToUnitGridHotSpot(
      {
        ...props.hotSpot,
        shapeData: {
          rectangle: {
            top: resizeTransform.top,
            left: resizeTransform.left,
            width: resizeTransform.width,
            height: resizeTransform.height,
          },
        },
      },
      container
    );
    updated = HiddenPictureUtils.Bound(updated);
    props.onUpdate(updated);
    setResizeTransform(undefined);
  };

  const fontSize = 'text-base';
  const x = transform?.x ?? 0;
  const y = transform?.y ?? 0;
  const color = props.hotSpot.points < 0 ? 'bg-[#CA2121]' : 'bg-green-400';

  return (
    <DndContext onDragMove={handleDragMove} onDragEnd={handleDragEnd}>
      <div
        ref={setNodeRef}
        className={`
          absolute
          box-content 
          ${props.selected ? 'z-10 cursor-move' : 'cursor-pointer'}
        `}
        style={{
          height: resizeTransform?.height ?? shape.height,
          width: resizeTransform?.width ?? shape.width,
          top: resizeTransform?.top ?? shape.top,
          left: resizeTransform?.left ?? shape.left,
          transform: `translate3d(${x}px, ${y}px, 0)`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          props.onSelect();
        }}
        {...attributes}
        {...listeners}
      >
        <div
          className={`
            ${color} bg-opacity-50 w-full h-full flex items-center justify-center select-none
          `}
        >
          {props.sequenced && (
            <div className={`${fontSize} font-mono font-bold`}>
              {props.index + 1}
            </div>
          )}
        </div>
        {props.ping && (
          <div
            className={`animate-ping-once absolute inset-0 h-full w-full ${color}`}
          />
        )}

        {props.selected && (
          <>
            <Handle id={props.hotSpot.id} pos='nw' />
            <Handle id={props.hotSpot.id} pos='ne' />
            <Handle id={props.hotSpot.id} pos='sw' />
            <Handle id={props.hotSpot.id} pos='se' />

            <Gutter id={props.hotSpot.id} pos='n' />
            <Gutter id={props.hotSpot.id} pos='s' />
            <Gutter id={props.hotSpot.id} pos='w' />
            <Gutter id={props.hotSpot.id} pos='e' />
          </>
        )}
      </div>
    </DndContext>
  );
}

function MainImageEditor(props: {
  showShapeButton: boolean;
  onShapeButtonClick: (shape: HotSpotShape) => void;
}): JSX.Element {
  const mainMedia = useWatch<HiddenPictureFormData, 'mainMedia'>({
    name: 'mainMedia',
  });

  const { setValue } = useFormContext<HiddenPictureFormData>();

  const handleMainMediaDeleted = () => {
    setValue('mainMedia', null, { shouldDirty: true });
    setValue('mainMediaData', null, { shouldDirty: true });
  };

  return (
    <div className='flex items-center mb-1 gap-3'>
      <div className='text-white font-bold'>Main Image</div>
      <Controller<HiddenPictureFormData, 'mainMediaData.volumeLevel'>
        name='mainMediaData.volumeLevel'
        render={({ field: { onChange, value } }) => (
          <VolumeSelect
            volumeLevel={value}
            onChange={onChange}
            disabled={mainMedia?.type !== MediaType.Video}
          />
        )}
      />
      <Controller<HiddenPictureFormData, 'mainMediaData.loop'>
        name='mainMediaData.loop'
        render={({ field: { onChange, value } }) => (
          <label className='flex items-center gap-2'>
            <input
              type='checkbox'
              className='checkbox-dark'
              checked={value ?? false}
              onChange={onChange}
              disabled={mainMedia?.type !== MediaType.Video}
            />
            <p
              className={`text-sm ${
                mainMedia?.type !== MediaType.Video
                  ? 'text-secondary'
                  : 'text-white'
              }`}
            >
              Loop
            </p>
          </label>
        )}
      />
      {mainMedia && (
        <button
          type='button'
          className='flex-none flex items-center justify-center text-red-002'
          onClick={handleMainMediaDeleted}
        >
          <DeleteIcon />
        </button>
      )}
      {props.showShapeButton && (
        <ShapeMenuButton onSelected={props.onShapeButtonClick} />
      )}
    </div>
  );
}

function ShapeMenuButton(props: { onSelected: (shape: HotSpotShape) => void }) {
  const [controlledVisibility, setControlledVisibility] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      interactive: true,
      visible: controlledVisibility,
      onVisibleChange: setControlledVisibility,
    });
  const hide = useLiveCallback(() => setControlledVisibility(false));

  return (
    <div className='ml-auto relative flex items-center'>
      <button
        className='btn-secondary w-7 h-7 rounded-md flex items-center justify-center'
        ref={setTriggerRef}
        type='button'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-4 h-4 fill-current'
          viewBox='0 0 256 256'
        >
          <rect width='256' height='256' fill='none' />
          <polygon
            points='64 64 24 184 104 184 64 64'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='16'
          />
          <circle
            cx='156'
            cy='76'
            r='44'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='16'
          />
          <rect
            x='136'
            y='152'
            width='88'
            height='56'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='16'
          />
        </svg>
      </button>
      {visible && (
        <Menu ref={setTooltipRef} {...getTooltipProps()}>
          <MenuItem
            text='Circle'
            onClick={() => {
              props.onSelected(HotSpotShape.Circle);
              hide();
            }}
          />
          <MenuItem
            text='Rectangle'
            onClick={() => {
              props.onSelected(HotSpotShape.Rectangle);
              hide();
            }}
          />
        </Menu>
      )}
    </div>
  );
}

function MainImageUploader(): JSX.Element {
  const mainMediaData = useWatch<HiddenPictureFormData, 'mainMediaData'>({
    name: 'mainMediaData',
  });

  const { setValue } = useFormContext<HiddenPictureFormData>();

  const handleMainMediaChanged = (media: Media) => {
    setValue('mainMedia', media, { shouldDirty: true });
    setValue(
      'mainMediaData',
      {
        id: media.id,
        volumeLevel: VolumeLevel.Full,
        loop: false,
      },
      { shouldDirty: true }
    );
  };

  return (
    <Controller<HiddenPictureFormData, 'mainMedia'>
      name='mainMedia'
      render={({ field: { value } }) => (
        <MediaUploader
          width={`w-full ${value ? 'select-none pointer-events-none' : ''}`}
          border=''
          image={true}
          video={true}
          audio={false}
          scene={EnumsMediaScene.MediaSceneBlockMedia}
          mediaData={mainMediaData}
          media={value}
          onUploadSuccess={handleMainMediaChanged}
          replace={false}
          allowDelete={false}
          pickMedia={(media) =>
            MediaUtils.PickMediaUrl(media, {
              priority: MediaPickPriorityHD,
            })
          }
        />
      )}
    />
  );
}

function AsymmetricGamePlaySwitcher(): JSX.Element {
  const everyoneClicks = useWatch<HiddenPictureFormData, 'everyoneClicks'>({
    name: 'everyoneClicks',
  });

  const tool = useWatch<HiddenPictureFormData, 'tool'>({
    name: 'tool',
  });

  return (
    <Controller<HiddenPictureFormData, 'asymmetricGamePlay'>
      name='asymmetricGamePlay'
      render={({ field: { name, onChange, value } }) => (
        <div>
          <Switcher
            label='Asymmetric Gameplay'
            name={name}
            value={value}
            onChange={onChange}
            description={{
              enabled:
                'Enabled: Non-team captains will see the asymmetric media file instead of the hidden picture board.',
              disabled: 'Disabled: Everyone will see the hidden picture.',
            }}
          />
          {everyoneClicks && value && (
            <div className='text-tertiary text-sm mt-2'>
              Note: this feature is not compatible with Everyone Clicks. All
              users will see the hidden picture.
            </div>
          )}
          {tool !== 'none' && value && (
            <div className='text-tertiary text-sm mt-2'>
              Note: this feature is not compatible with Tools.
            </div>
          )}
        </div>
      )}
    />
  );
}

function AsymmetricMediaUploader(): JSX.Element {
  const mediaData = useWatch<HiddenPictureFormData, 'asymmetricMediaData'>({
    name: 'asymmetricMediaData',
  });
  const enabled = useWatch<HiddenPictureFormData, 'asymmetricGamePlay'>({
    name: 'asymmetricGamePlay',
  });

  const { setValue } = useFormContext<HiddenPictureFormData>();

  const handleMediaChanged = useLiveCallback(
    (mediaData: MediaData | null, media: Media | null) => {
      setValue('asymmetricMedia', media, { shouldDirty: true });
      setValue('asymmetricMediaData', media ? mediaData : null, {
        shouldDirty: true,
      });
    }
  );

  return (
    <Controller<HiddenPictureFormData, 'asymmetricMedia'>
      name='asymmetricMedia'
      render={({ field: { value } }) => (
        <div
          className={`
            w-full
            ${enabled ? '' : 'opacity-50 pointer-events-none'}
          `}
        >
          <MediaEditor
            width='w-full'
            title='Asymmetric Media'
            video
            image
            loopSelectable
            scene={EnumsMediaScene.MediaSceneBlockMedia}
            mediaData={mediaData}
            media={value}
            onChange={handleMediaChanged}
            extraNotice='This media will only be shown to players who cannot click.'
          />
        </div>
      )}
    />
  );
}

type AsymmetricPinDropVisibilityOption =
  Option<EnumsHiddenPictureAsymmetricPinDropVisibility> & {
    description: string;
  };

const pinDropVisibilityOptions: AsymmetricPinDropVisibilityOption[] = [
  {
    label: 'Hidden',
    value:
      EnumsHiddenPictureAsymmetricPinDropVisibility.HiddenPictureAsymmetricPinDropVisibilityHidden,
    description:
      'Pin drops by the team captain will be hidden from the rest of the team.',
  },
  {
    label: 'Visible',
    value:
      EnumsHiddenPictureAsymmetricPinDropVisibility.HiddenPictureAsymmetricPinDropVisibilityVisible,
    description:
      'Pin drops by the team captain will be visible to the rest of the team. Pins will be rendered on top of the asymmetric media element.',
  },
];

function AsymmetricPinDropVisibilitySelect() {
  const enabled = useWatch<HiddenPictureFormData, 'asymmetricGamePlay'>({
    name: 'asymmetricGamePlay',
  });

  const styles = useMemo(
    () => buildReactSelectStyles<AsymmetricPinDropVisibilityOption>(),
    []
  );

  return (
    <Controller<HiddenPictureFormData, 'asymmetricPinDropVisibility'>
      name='asymmetricPinDropVisibility'
      render={({ field: { value, onChange } }) => {
        const selected =
          pinDropVisibilityOptions.find((o) => o.value === value) ?? null;

        const handleChange = (
          option: SingleValue<AsymmetricPinDropVisibilityOption>
        ) => {
          if (option) {
            onChange(option.value);
          }
        };

        return (
          <div
            className={`flex gap-3 ${
              enabled ? '' : 'opacity-50 pointer-events-none'
            }`}
          >
            <div>
              <div className='text-white font-bold mb-1'>
                Pin Drop Visibility
              </div>
              <div className='w-full text-icon-gray text-sm font-normal'>
                {selected?.description ??
                  'Describes how pin drops are shown to non team captains.'}
              </div>
            </div>
            <div className='flex-none'>
              <Select<AsymmetricPinDropVisibilityOption>
                classNamePrefix='select-box-v2'
                styles={styles}
                value={selected}
                options={pinDropVisibilityOptions}
                onChange={handleChange}
                isSearchable={false}
                isDisabled={!enabled}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

type AsymmetricPinDropAudibilityOption =
  Option<EnumsHiddenPictureAsymmetricPinDropAudibility> & {
    description: string;
  };

const pinDropAudibilityOptions: AsymmetricPinDropAudibilityOption[] = [
  {
    label: 'Muted',
    value:
      EnumsHiddenPictureAsymmetricPinDropAudibility.HiddenPictureAsymmetricPinDropAudibilityMuted,
    description:
      'Pin drops by the team captain will be muted to the rest of the team.',
  },
  {
    label: 'Audible',
    value:
      EnumsHiddenPictureAsymmetricPinDropAudibility.HiddenPictureAsymmetricPinDropAudibilityAudible,
    description:
      'Pin drops by the team captain will be audible to the rest of the team.',
  },
];

function AsymmetricPinDropAudibilitySelect() {
  const enabled = useWatch<HiddenPictureFormData, 'asymmetricGamePlay'>({
    name: 'asymmetricGamePlay',
  });

  const styles = useMemo(
    () => buildReactSelectStyles<AsymmetricPinDropAudibilityOption>(),
    []
  );

  return (
    <Controller<HiddenPictureFormData, 'asymmetricPinDropAudibility'>
      name='asymmetricPinDropAudibility'
      render={({ field: { value, onChange } }) => {
        const selected =
          pinDropAudibilityOptions.find((o) => o.value === value) ?? null;

        const handleChange = (
          option: SingleValue<AsymmetricPinDropAudibilityOption>
        ) => {
          if (option) {
            onChange(option.value);
          }
        };

        return (
          <div
            className={`flex gap-3 ${
              enabled ? '' : 'opacity-50 pointer-events-none'
            }`}
          >
            <div>
              <div className='text-white font-bold mb-1'>
                Pin Drop Audibility
              </div>
              <div className='w-full text-icon-gray text-sm font-normal'>
                {selected?.description ??
                  'Describes how pin drops are heard by non team captains.'}
              </div>
            </div>
            <div className='flex-none'>
              <Select<AsymmetricPinDropAudibilityOption>
                classNamePrefix='select-box-v2'
                styles={styles}
                value={selected}
                options={pinDropAudibilityOptions}
                onChange={handleChange}
                isSearchable={false}
                isDisabled={!enabled}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

type SelectedHotSpot = {
  id: string;
  ping: boolean;
  autoScroll: boolean;
};

function HotSpotImageEditor(props: {
  showApplyHotSpotsToAllButton: boolean;
  onApplyHotSpotsToAll: (hotSpots: HotSpotV2[]) => void;
  triggerConfirmationModal: ConfirmCancelModalConfigurator;
}): JSX.Element {
  const hotSpotEditorsRef = useRef<HTMLDivElement>(null);

  const [containerRef, containerRect] = useMeasure({
    polyfill: ResizeObserver,
  });

  const { fields, append, remove, move, update } = useFieldArray<
    HiddenPictureFormData,
    'hotSpotsV2',
    'key'
  >({
    name: 'hotSpotsV2',
    keyName: 'key', // important, otherwise rhf will overwrite 'id'
  });

  const [selectedHotSpot, setSelectedHotSpot] = useState<
    SelectedHotSpot | undefined
  >(undefined);

  const sequenced = useWatch<HiddenPictureFormData, 'sequenced'>({
    name: 'sequenced',
  });

  const mainMedia = useWatch<HiddenPictureFormData, 'mainMedia'>({
    name: 'mainMedia',
  });

  useLayoutEffect(() => {
    if (
      !hotSpotEditorsRef.current ||
      !selectedHotSpot ||
      !selectedHotSpot.autoScroll
    )
      return;

    // find the index of this hot spot
    const index = fields.findIndex((h) => h.id === selectedHotSpot.id);
    if (index === -1) return;

    // set the scroll position so it's in view
    hotSpotEditorsRef.current.scrollTop =
      index * (HOT_SPOT_EDITOR_HEIGHT_PX + HOT_SPOT_EDITOR_GAP_PX);
  }, [fields, selectedHotSpot]);

  const handleAddMore = (props: {
    shape: HotSpotShape;
    shapeData: HotSpotShapeData;
  }) => {
    const id = uuidv4();
    const newHotSpot = {
      ...props,
      id,
      name: '',
      points: 10,
    };
    append(newHotSpot);
    setSelectedHotSpot({ id, ping: true, autoScroll: true });
  };

  const handleHotSpotDragged = useLiveCallback((event: DragEndEvent) => {
    if (!containerRect || (event.delta.x === 0 && event.delta.y === 0)) return;

    const index = fields.findIndex((c) => c.id === event.active.id);
    if (index === -1) return;

    // convert the delta to a unit delta
    const unitDelta = HiddenPictureUtils.ToUnitGrid(event.delta, {
      maxX: containerRect.width,
      maxY: containerRect.height,
    });
    const hotSpot = fields[index];
    switch (hotSpot.shape) {
      case HotSpotShape.Circle: {
        const shapeData = hotSpot.shapeData.circle;
        if (!shapeData) return;

        const updated = HiddenPictureUtils.Bound({
          ...hotSpot,
          shapeData: {
            circle: {
              ...shapeData,
              top: shapeData.top + unitDelta.y,
              left: shapeData.left + unitDelta.x,
            },
          },
        });
        update(index, updated);
        break;
      }

      case HotSpotShape.Rectangle: {
        const shapeData = hotSpot.shapeData.rectangle;
        if (!shapeData) return;

        const updated = HiddenPictureUtils.Bound({
          ...hotSpot,
          shapeData: {
            rectangle: {
              ...shapeData,
              top: shapeData.top + unitDelta.y,
              left: shapeData.left + unitDelta.x,
            },
          },
        });
        update(index, updated);
        break;
      }

      default:
        break;
    }
  });

  const handleSelectHotSpotFromSideBar = useCallback(
    (id: string) => {
      setSelectedHotSpot({ id, ping: true, autoScroll: false });
    },
    [setSelectedHotSpot]
  );

  const handleSelectHotSpotFromImage = useCallback(
    (id: string) => {
      setSelectedHotSpot({ id, ping: false, autoScroll: true });
    },
    [setSelectedHotSpot]
  );

  const hotSpots = useMemo(() => {
    return fields.map((hotSpot, index) => {
      switch (hotSpot.shape) {
        case HotSpotShape.Circle:
          return (
            <HotSpotCircle
              key={hotSpot.id}
              hotSpot={hotSpot}
              index={index}
              containerRect={containerRect}
              onSelect={() => handleSelectHotSpotFromImage(hotSpot.id)}
              onUpdate={(updated) => update(index, updated)}
              onDelete={() => remove(index)}
              selected={selectedHotSpot?.id === hotSpot.id}
              ping={selectedHotSpot?.ping && selectedHotSpot.id === hotSpot.id}
              sequenced={sequenced}
            />
          );
        case HotSpotShape.Rectangle:
          return (
            <HotSpotRectangle
              key={hotSpot.id}
              hotSpot={hotSpot}
              index={index}
              containerRect={containerRect}
              onSelect={() => handleSelectHotSpotFromImage(hotSpot.id)}
              onUpdate={(updated) => update(index, updated)}
              onDelete={() => remove(index)}
              selected={selectedHotSpot?.id === hotSpot.id}
              ping={selectedHotSpot?.ping && selectedHotSpot.id === hotSpot.id}
              sequenced={sequenced}
            />
          );
        default:
          return null;
      }
    });
  }, [
    fields,
    containerRect,
    selectedHotSpot?.id,
    selectedHotSpot?.ping,
    sequenced,
    handleSelectHotSpotFromImage,
    update,
    remove,
  ]);

  const hotSpotEditors = useMemo(() => {
    if (sequenced) {
      return (
        <DragDropList
          type='hotspots-editors'
          items={fields}
          onMove={move}
          render={({ item, index, drag, ref, style }) => {
            style.marginTop = index === 0 ? 0 : HOT_SPOT_EDITOR_GAP_PX;
            return (
              <div className='w-full' ref={ref} style={style}>
                <HotSpotEditor
                  initialData={item}
                  onDelete={() => remove(index)}
                  index={index}
                  drag={drag}
                  selected={selectedHotSpot?.id === item.id}
                  onSelect={() => handleSelectHotSpotFromSideBar(item.id)}
                  onUpdate={(updated) => {
                    update(index, updated);
                  }}
                  onDuplicate={() => {
                    const id = uuidv4();
                    const newHotSpot = {
                      ...item,
                      id,
                      name: `Copy of ${item.name}`,
                    };
                    append(newHotSpot);
                    setSelectedHotSpot({ id, ping: true, autoScroll: true });
                  }}
                  sequenced={true}
                />
              </div>
            );
          }}
        />
      );
    }
    return (
      <div>
        {fields.map((hotSpot, index) => (
          <div
            className='w-full'
            key={hotSpot.id}
            style={{ marginTop: index === 0 ? 0 : HOT_SPOT_EDITOR_GAP_PX }}
          >
            <HotSpotEditor
              initialData={hotSpot}
              onDelete={() => remove(index)}
              onSelect={() => handleSelectHotSpotFromSideBar(hotSpot.id)}
              onUpdate={(updated) => {
                update(index, updated);
              }}
              onDuplicate={() => {
                const id = uuidv4();
                const newHotSpot = {
                  ...hotSpot,
                  id,
                  name: `Copy of ${hotSpot.name}`,
                };
                append(newHotSpot);
                setSelectedHotSpot({ id, ping: true, autoScroll: true });
              }}
              selected={selectedHotSpot?.id === hotSpot.id}
              index={index}
              sequenced={false}
            />
          </div>
        ))}
      </div>
    );
  }, [
    sequenced,
    fields,
    move,
    selectedHotSpot?.id,
    remove,
    handleSelectHotSpotFromSideBar,
    update,
    append,
  ]);

  const onClick = useLiveCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (mainMedia && e.detail === 2) {
      const bounds = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;

      const unitDelta = HiddenPictureUtils.ToUnitGrid(
        { x, y },
        {
          maxX: containerRect.width,
          maxY: containerRect.height,
        }
      );

      if (e.metaKey) {
        const height = 0.05;
        const width = height / ASPECT_RATIO;
        handleAddMore({
          shape: HotSpotShape.Rectangle,
          shapeData: {
            rectangle: {
              top: unitDelta.y - height / 2,
              left: unitDelta.x - width / 2,
              height,
              width,
            },
          },
        });
      } else {
        const radius = 0.025;
        handleAddMore({
          shape: HotSpotShape.Circle,
          shapeData: {
            circle: {
              top: unitDelta.y - radius,
              left: unitDelta.x - radius / ASPECT_RATIO,
              radius,
            },
          },
        });
      }
    } else {
      setSelectedHotSpot(undefined);
    }
  });

  const handleClickAddItem = useLiveCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.metaKey) {
        handleAddMore({
          shape: HotSpotShape.Rectangle,
          shapeData: {
            rectangle: {
              top: 0,
              left: 0,
              height: 0.05,
              width: 0.05 / ASPECT_RATIO,
            },
          },
        });
      } else {
        handleAddMore({
          shape: HotSpotShape.Circle,
          shapeData: {
            circle: {
              top: 0,
              left: 0,
              radius: 0.025,
            },
          },
        });
      }
    }
  );

  const handleShapeButtonClick = useLiveCallback(
    async (shape: HotSpotShape) => {
      if (selectedHotSpot === undefined) {
        const { result } = await props.triggerConfirmationModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='px-5 py-2'>
              <ConfirmCancelModalHeading>
                Are you sure?
              </ConfirmCancelModalHeading>
              <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
                This will convert all hot spots to the selected shape.
              </ConfirmCancelModalText>
            </div>
          ),
          cancelBtnLabel: 'Cancel',
          confirmBtnLabel: 'Continue',
        });
        if (result === 'canceled') {
          return;
        }
      }

      for (let i = 0; i < fields.length; i++) {
        const hotSpot = fields[i];
        const shouldConvert =
          selectedHotSpot === undefined || hotSpot.id === selectedHotSpot.id;
        if (!shouldConvert) continue;

        update(i, HiddenPictureUtils.ConvertShape(hotSpot, shape));
      }
    }
  );

  return (
    <div className='w-full grid grid-rows-2 lg:grid-rows-none lg:grid-cols-4 gap-6'>
      <div className='col-span-full lg:col-span-3 h-full'>
        <MainImageEditor
          showShapeButton={Boolean(selectedHotSpot || hotSpots.length > 0)}
          onShapeButtonClick={handleShapeButtonClick}
        />
        <DndContext onDragEnd={handleHotSpotDragged}>
          <div
            ref={containerRef}
            className='relative aspect-w-16 aspect-h-9 isolate overflow-hidden border border-dashed border-secondary rounded-xl'
            onClick={onClick}
          >
            <MainImageUploader />
            {mainMedia && hotSpots}
          </div>
          {props.showApplyHotSpotsToAllButton && fields.length > 0 && (
            <div className='mt-1 flex w-full justify-end'>
              <ApplyHotSpotsToAllButton
                onClick={props.onApplyHotSpotsToAll}
                triggerConfirmationModal={props.triggerConfirmationModal}
              />
            </div>
          )}
        </DndContext>
      </div>
      <div className='col-span-full lg:col-span-1 relative'>
        <div className='absolute inset-0 overflow-hidden flex flex-col'>
          <div className='flex items-baseline justify-between pb-3'>
            <div className='text-white font-bold'>
              Hidden Items ({fields.length})
            </div>
            <div className='flex items-baseline gap-4'>
              <button
                type='button'
                className='btn text-xs font-bold text-primary'
                onClick={handleClickAddItem}
              >
                Add Item
              </button>
            </div>
          </div>
          <div
            ref={hotSpotEditorsRef}
            className='flex-1 overflow-y-scroll overflow-x-hidden scrollbar'
          >
            {hotSpotEditors}
          </div>
        </div>
      </div>
    </div>
  );
}

function FormButtons(props: {
  onCancel: () => void;
  onSubmit: () => void;
  triggerConfirmationModal: ConfirmCancelModalConfigurator;
}): JSX.Element {
  const { getValues, setValue } = useFormContext<HiddenPictureFormData>();
  const { isValid, isDirty, isSubmitting } = useFormState();

  const handleCancel = async () => {
    if (!isDirty) {
      props.onCancel();
      return;
    }

    const { result } = await props.triggerConfirmationModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            You have unsaved changes. Are you sure you want to discard them?
          </ConfirmCancelModalText>
        </div>
      ),
      cancelBtnLabel: 'Keep editing',
      confirmBtnLabel: 'Discard changes',
      confirmBtnVariant: 'delete',
    });
    if (result === 'confirmed') {
      props.onCancel();
    }
  };

  const handleSubmit = async () => {
    const [sequenced, hotSpots] = getValues(['sequenced', 'hotSpotsV2']);
    if (sequenced) {
      // check if any hotspots are negative. we won't allow this.
      const hasNegativeHotSpots = (hotSpots ?? []).some((h) => h.points < 0);
      if (hasNegativeHotSpots) {
        const { result } = await props.triggerConfirmationModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='px-5 py-2'>
              <ConfirmCancelModalHeading>
                Disable Item Sequencing?
              </ConfirmCancelModalHeading>
              <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
                Hot spots with negative points are incompatible with item
                sequencing. Click “Continue” to disable sequencing and save.
              </ConfirmCancelModalText>
            </div>
          ),
          cancelBtnLabel: 'Cancel',
          confirmBtnLabel: 'Continue',
        });
        if (result !== 'confirmed') {
          return;
        }
        setValue('sequenced', false);
      }
    }
    props.onSubmit();
  };

  return (
    <div className='flex justify-center items-center gap-4'>
      <button
        type='button'
        className='btn-secondary w-32 h-10'
        onClick={handleCancel}
        disabled={isSubmitting}
      >
        Cancel
      </button>
      <button
        type='button'
        className='btn-primary w-32 h-10 flex items-center justify-center'
        onClick={handleSubmit}
        disabled={!isDirty || !isValid || isSubmitting}
      >
        {isSubmitting ? <Loading text='' imgClassName='w-5 h-5' /> : <>Save</>}
      </button>
    </div>
  );
}

type HiddenPictureFormData = Omit<HiddenPicture, 'id'>;

function defaultFormData(): HiddenPictureFormData {
  return {
    tool: 'none',
    sequenced: false,
    hotSpotsV2: [],
    question: '',
    everyoneClicks: false,
    name: '',
    incorrectAnswerPenalty: 0,
    mainMedia: null,
    mainMediaData: null,
    asymmetricGamePlay: false,
    asymmetricMedia: null,
    asymmetricMediaData: null,
    asymmetricPinDropVisibility:
      EnumsHiddenPictureAsymmetricPinDropVisibility.HiddenPictureAsymmetricPinDropVisibilityHidden,
    asymmetricPinDropAudibility:
      EnumsHiddenPictureAsymmetricPinDropAudibility.HiddenPictureAsymmetricPinDropAudibilityMuted,
  };
}

type HiddenPictureEditorProps = {
  onCancel: () => void;
  onSubmit: (data: HiddenPictureFormData) => void;
  initialData: HiddenPictureFormData | null;
  showApplyAdditionalSettingsToAllButton: boolean;
  onApplyAdditionalSettingsToAll: (settings: AdditionalSettings) => void;
  showApplyHotSpotsToAllButton: boolean;
  onApplyHotSpotsToAll: (hotSpots: HotSpotV2[]) => void;
  showApplyAsymmetricSettingsToAllButton: boolean;
  onApplyAsymmetricSettingsToAll: (settings: AsymmetricSettings) => void;
};

export function EditHiddenPictureButton(
  props: Omit<HiddenPictureEditorProps, 'onCancel'>
): JSX.Element {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  const handleClick = () => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => {
        const handleSubmit = (data: HiddenPictureFormData) => {
          props.onSubmit(data);
          p.internalOnConfirm();
        };

        return (
          <ModalWrapper borderStyle='none' containerClassName='fixed inset-0'>
            <HiddenPictureEditor
              {...props}
              onCancel={p.internalOnCancel}
              onSubmit={handleSubmit}
            />
          </ModalWrapper>
        );
      },
    });
  };

  return (
    <button
      type='button'
      className='btn underline text-icon-gray text-sms'
      onClick={handleClick}
    >
      Edit
    </button>
  );
}

function ApplyToAllButton(props: {
  onClick: (data: HiddenPictureFormData) => void;
  triggerConfirmationModal: ConfirmCancelModalConfigurator;
}): JSX.Element {
  const { onClick, triggerConfirmationModal } = props;
  const { getValues } = useFormContext<HiddenPictureFormData>();

  const handleClick = useCallback(async () => {
    const { result } = await triggerConfirmationModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            <>
              This action will apply these settings to <strong>all</strong> the
              pictures in this block including this one.
            </>
          </ConfirmCancelModalText>
        </div>
      ),
      cancelBtnLabel: 'Cancel',
      confirmBtnLabel: 'Apply',
    });
    if (result !== 'confirmed') {
      return;
    }

    onClick(getValues());
  }, [getValues, onClick, triggerConfirmationModal]);

  return (
    <button
      type='button'
      className='btn text-sms font-medium text-primary'
      onClick={handleClick}
    >
      Apply to all pictures in block
    </button>
  );
}

function ApplyHotSpotsToAllButton(props: {
  onClick: (hotSpots: HotSpotV2[]) => void;
  triggerConfirmationModal: ConfirmCancelModalConfigurator;
}): JSX.Element {
  const { onClick, triggerConfirmationModal } = props;
  const { getValues } = useFormContext<HiddenPictureFormData>();

  const handleClick = useCallback(async () => {
    const { result } = await triggerConfirmationModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            <>
              This action will apply these hotspots to <strong>all</strong> the
              pictures in this block including this one. It will{' '}
              <strong>replace</strong> any existing hotspots and cannot be
              undone.
            </>
          </ConfirmCancelModalText>
        </div>
      ),
      cancelBtnLabel: 'Cancel',
      confirmBtnLabel: 'Apply',
    });
    if (result !== 'confirmed') {
      return;
    }

    const hotSpots = getValues('hotSpotsV2');
    onClick(hotSpots ?? []);
  }, [getValues, onClick, triggerConfirmationModal]);

  return (
    <button
      type='button'
      className='btn text-xs text-primary hover:underline'
      onClick={handleClick}
    >
      Apply hotspots to all pictures in block
    </button>
  );
}

export function HiddenPictureEditor(
  props: HiddenPictureEditorProps
): JSX.Element {
  const [confirmationModal, triggerConfirmationModal] =
    useCancelConfirmModalStateRoot();

  const form = useForm<HiddenPictureFormData>({
    mode: 'onChange',
    defaultValues: props.initialData ?? defaultFormData(),
  });

  const { handleSubmit, register } = form;
  const onSubmit = useMemo(
    () => handleSubmit(props.onSubmit),
    [handleSubmit, props.onSubmit]
  );

  const handleApplyAdditionalSettingsToAll = useLiveCallback(
    (data: HiddenPictureFormData) => {
      props.onApplyAdditionalSettingsToAll({
        everyoneClicks: data.everyoneClicks,
        sequenced: data.sequenced,
        tool: data.tool,
        incorrectAnswerPenalty: data.incorrectAnswerPenalty,
      });
    }
  );

  const handleApplyAsymmetricSettingsToAll = useLiveCallback(
    (data: HiddenPictureFormData) => {
      props.onApplyAsymmetricSettingsToAll({
        asymmetricGamePlay: data.asymmetricGamePlay,
        asymmetricMedia: data.asymmetricMedia,
        asymmetricMediaData: data.asymmetricMediaData,
        asymmetricPinDropVisibility: data.asymmetricPinDropVisibility,
        asymmetricPinDropAudibility: data.asymmetricPinDropAudibility,
      });
    }
  );

  return (
    <div className='relative w-full h-full overflow-y-scroll scrollbar'>
      {confirmationModal && (
        <div className='fixed inset-0 overflow-hidden rounded-xl z-10'>
          {confirmationModal}
        </div>
      )}
      <FormProvider<HiddenPictureFormData> {...form}>
        <div className='w-full h-full flex flex-col'>
          <header className='sticky z-5 top-0 py-7 w-full px-8 flex items-center justify-between bg-black border-b border-secondary'>
            <div className='text-center text-white font-bold text-2xl'>
              Hidden Picture
            </div>
            <FormButtons
              onSubmit={onSubmit}
              onCancel={props.onCancel}
              triggerConfirmationModal={triggerConfirmationModal}
            />
          </header>

          <main>
            <section className='w-full px-8 pt-10 flex items-center gap-3'>
              <div className='flex-1'>
                <div className='pb-1 font-bold'>Internal Label</div>
                <input
                  type='text'
                  className='field w-full h-10 mb-0'
                  placeholder='Max 100 characters'
                  maxLength={100}
                  {...register('name', { maxLength: 100 })}
                />
              </div>
              <div className='flex-1'>
                <div className='pb-1 font-bold'>Question</div>
                <input
                  type='text'
                  className='field h-10 m-0 w-full'
                  placeholder='Max 300 characters'
                  maxLength={300}
                  {...register('question', { maxLength: 300 })}
                />
              </div>
            </section>
            <div className='w-full px-8 py-5'>
              <hr className='w-full border border-secondary' />
            </div>
            <section className='w-full px-8'>
              <HotSpotImageEditor
                showApplyHotSpotsToAllButton={
                  props.showApplyHotSpotsToAllButton
                }
                onApplyHotSpotsToAll={props.onApplyHotSpotsToAll}
                triggerConfirmationModal={triggerConfirmationModal}
              />
            </section>
            <div className='w-full px-8 pt-8 pb-5'>
              <hr className='w-full border border-secondary' />
            </div>

            <section className='w-full px-8 mb-15'>
              <div className='flex items-baseline gap-4'>
                <div className='text-white font-bold text-lg tracking-wide pb-4'>
                  Additional Settings
                </div>
                {props.showApplyAdditionalSettingsToAllButton && (
                  <ApplyToAllButton
                    onClick={handleApplyAdditionalSettingsToAll}
                    triggerConfirmationModal={triggerConfirmationModal}
                  />
                )}
              </div>
              <div className='grid grid-cols-3 gap-x-18 gap-y-10'>
                <Controller<HiddenPictureFormData, 'everyoneClicks'>
                  name='everyoneClicks'
                  render={({ field: { name, onChange, value } }) => (
                    <Switcher
                      label='Everyone Clicks'
                      name={name}
                      value={value}
                      onChange={onChange}
                      description={{
                        enabled: 'Enabled: All team members may click.',
                        disabled: 'Disabled: Only the Team Captain may click.',
                      }}
                    />
                  )}
                />

                <Controller<HiddenPictureFormData, 'sequenced'>
                  name='sequenced'
                  render={({ field: { name, onChange, value } }) => (
                    <Switcher
                      label='Item Sequencing'
                      name={name}
                      value={value}
                      onChange={onChange}
                      description={{
                        enabled:
                          'Enabled: Items must be clicked in sequential order.',
                        disabled:
                          'Disabled: Items can be clicked in any order.',
                      }}
                    />
                  )}
                />

                <Controller<HiddenPictureFormData, 'tool'>
                  name='tool'
                  render={({ field: { onChange, value } }) => (
                    <ToolSelector value={value} onChange={onChange} />
                  )}
                />

                <Controller<HiddenPictureFormData, 'incorrectAnswerPenalty'>
                  name='incorrectAnswerPenalty'
                  render={({ field: { onChange, value } }) => (
                    <IncorrectAnswerPenaltyInput
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </section>

            <div className='w-full px-8 pt-8 pb-5'>
              <hr className='w-full border border-secondary' />
            </div>

            <section className='w-full px-8 mb-15'>
              <div className='flex items-baseline gap-4'>
                <div className='text-white font-bold text-lg tracking-wide pb-4'>
                  Asymmetric Settings
                </div>
                {props.showApplyAdditionalSettingsToAllButton && (
                  <ApplyToAllButton
                    onClick={handleApplyAsymmetricSettingsToAll}
                    triggerConfirmationModal={triggerConfirmationModal}
                  />
                )}
              </div>
              <div className='grid grid-cols-3 gap-x-18 gap-y-10'>
                <AsymmetricGamePlaySwitcher />
                <AsymmetricPinDropVisibilitySelect />
                <AsymmetricPinDropAudibilitySelect />
                <AsymmetricMediaUploader />
              </div>
            </section>
          </main>
        </div>
      </FormProvider>
    </div>
  );
}
