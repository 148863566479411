import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { getLogger } from '../../../../../logger/logger';
import { assertExhaustive } from '../../../../../utils/common';
import { createProvider } from '../../../../../utils/createProvider';
import { useAudienceControlSender } from '../../../../Audience/AudienceControlProviders';
import { useCohostPositionManager } from '../../../../Cohost/CohostPositionManagerProvider';
import { useCycleTeamCaptains } from '../../../../TeamAPI/TeamV1';
import { useTownhallAPI } from '../../../../Townhall';
import { ondConfigureImmediateBlockEnd } from '../../../OndPhaseRunner/OndPhaseRunner';
import { type PlaybackDescItem } from '../../../Playback/intoPlayback';
import { getBlockLifecycleRules } from './utils';

const log = getLogger().scoped('BlockLifecycleRulesEvaluator');

type BlockLifecycleRulesEvaluator = (
  nextStatus: number,
  pbi: Nullable<PlaybackDescItem>
) => Promise<void>;

const { Provider, useCreatedContext: useBlockLifecycleRulesEvaluator } =
  createProvider<BlockLifecycleRulesEvaluator>('BlockLifecycleRulesEvaluator');

export { useBlockLifecycleRulesEvaluator };

export function BlockLifecycleRulesEvaluatorProvider(props: {
  children?: React.ReactNode;
}) {
  const townhallAPI = useTownhallAPI();
  const cohostPositionManager = useCohostPositionManager();
  const audienceControl = useAudienceControlSender();
  const cycleTeamCaptains = useCycleTeamCaptains();

  const evaluate = useLiveCallback(
    async (nextStatus: number, pbi: Nullable<PlaybackDescItem>) => {
      await evaluateLifecycleRules(nextStatus, pbi, {
        townhallAPI,
        cohostPositionManager,
        audienceControl,
        cycleTeamCaptains,
        endBlockImmediately: async () => {
          await ondConfigureImmediateBlockEnd(true);
        },
      });
    }
  );

  return <Provider value={evaluate}>{props.children}</Provider>;
}

async function evaluateLifecycleRules(
  nextStatus: number,
  pbi: Nullable<PlaybackDescItem>,
  deps: {
    townhallAPI: ReturnType<typeof useTownhallAPI>;
    cohostPositionManager: ReturnType<typeof useCohostPositionManager>;
    audienceControl: ReturnType<typeof useAudienceControlSender>;
    cycleTeamCaptains: ReturnType<typeof useCycleTeamCaptains>;
    endBlockImmediately: () => Promise<void>;
  }
) {
  if (!pbi) return;

  const lifecycleRules = getBlockLifecycleRules(pbi);
  if (lifecycleRules.length === 0) return;

  for (const rule of lifecycleRules) {
    const { condition, operation } = rule;
    if (
      condition.type === 'on-status' &&
      condition.props.value === nextStatus
    ) {
      log.info('lifecycle rule triggered', { rule });
      try {
        switch (operation.type) {
          case 'set-townhall-mode': {
            await deps.townhallAPI.setNext({
              mode: operation.props.value,
              countdownSec: 0,
              source: 'ondphaserunner',
              type: 'global',
            });
            break;
          }
          case 'set-cohost-position': {
            await deps.cohostPositionManager.setNamedPosition(
              operation.props.value,
              true
            );
            break;
          }
          case 'set-cohost-visibility': {
            await deps.cohostPositionManager.setVisibility(
              operation.props.value
            );
            break;
          }
          case 'mute-audience': {
            await deps.audienceControl.mute();
            break;
          }
          case 'cycle-team-captains': {
            await deps.cycleTeamCaptains();
            break;
          }
          case 'end-block-immediately': {
            await deps.endBlockImmediately();
            break;
          }
          default:
            assertExhaustive(operation);
            break;
        }
      } catch (e) {
        log.error('failed to execute lifecycle rule', { rule, e });
      }
    }
  }
}
