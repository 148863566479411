import { useEffect, useRef } from 'react';
import { usePreviousDistinct } from 'react-use';

import {
  type SpotlightBlockV2,
  SpotlightBlockV2GameSessionStatus,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import logger from '../../../../logger/logger';
import { err2s, sleep } from '../../../../utils/common';
import { useCohostClientId, useHostClientId } from '../../../Player';
import {
  StageMode,
  useSelectOnStageMembers,
  useStageControlAPI,
} from '../../../Stage';
import {
  LVOBroadcastPlayer,
  lvoTTSRequestFromPlan,
} from '../../../VoiceOver/LocalizedVoiceOvers';
import { VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import {
  useCreateGameInfoSnapshot,
  useGameSessionStatus,
  useGetOndGameCurrentPlaybackItem,
  useIsLiveGamePlay,
} from '../../hooks';
import { ondWaitReadyForSkip } from '../../OndPhaseRunner/OndPhaseRunner';
import { type GameControlProps } from '../Common/GameControl/types';
import {
  SpotlightV2VotingStatus,
  useSpotlightV2Control,
  useSpotlightV2VotingStatus,
} from './SpotlightBlockV2Provider';
import { usePreselectClientIds } from './utils';

const log = logger.scoped('spotlightV2-block-game-control');

type SharedProps = GameControlProps<SpotlightBlockV2>;

export function SpotlightBlockV2Control({
  block,
}: SharedProps): JSX.Element | null {
  const isLive = useIsLiveGamePlay();
  const preselectedClientIds = usePreselectClientIds(block);
  const hostClientId = useHostClientId();
  const cohostClientId = useCohostClientId();
  const stageMembers = useSelectOnStageMembers(StageMode.SPOTLIGHT_BLOCK, {
    includeReconnectingMembers: true,
  });

  const stageControl = useStageControlAPI();
  const gameSessionStatus =
    useGameSessionStatus<SpotlightBlockV2GameSessionStatus>();
  const getCurrentPlaybackItem = useGetOndGameCurrentPlaybackItem();

  const { initGame, resetGame, stopVoteCelebrating } = useSpotlightV2Control();

  const votingStatus = useSpotlightV2VotingStatus();

  const currBlockId = block.id;
  const prevBlockId = usePreviousDistinct(currBlockId);

  const isInitializing = useRef(false);
  const initialize = useLiveCallback(async () => {
    if (isInitializing.current) return;
    isInitializing.current = true;
    try {
      await initGame(block);
      await stageControl.updateStageMode(StageMode.SPOTLIGHT_BLOCK);
    } finally {
      isInitializing.current = false;
    }
  });

  // initialize the game on when loaded.
  useEffect(() => {
    if (gameSessionStatus !== SpotlightBlockV2GameSessionStatus.LOADED) return;
    initialize();
  }, [gameSessionStatus, initialize]);

  const isResetting = useRef(false);
  const reset = useLiveCallback(async (debug: string) => {
    if (isResetting.current) return;
    isResetting.current = true;
    try {
      await resetGame(debug);
      await stageControl.leaveAll([hostClientId, cohostClientId]);
      await stageControl.updateStageMode(StageMode.BOS);
    } finally {
      isResetting.current = false;
    }
  });

  // reset the game when unloaded.
  useEffect(() => {
    return () => {
      reset('game unloaded');
    };
  }, [reset]);

  const handleBlockChange = useLiveCallback(async () => {
    await reset(`block id changed prev:${prevBlockId} curr:${currBlockId}`);
    await initialize();
  });

  // in case spotlightv2s are back to back, only the block id will change.
  useEffect(() => {
    if (prevBlockId && prevBlockId !== currBlockId) {
      handleBlockChange();
    }
  }, [currBlockId, prevBlockId, handleBlockChange]);

  useEffect(() => {
    if (
      isLive ||
      gameSessionStatus !== SpotlightBlockV2GameSessionStatus.PRESENTING
    )
      return;

    log.info('bringing on stage', { preselectedClientIds });

    const promises = preselectedClientIds.map((clientId) => {
      return stageControl.join(clientId, StageMode.SPOTLIGHT_BLOCK);
    });

    Promise.all(promises).catch((err) => {
      log.error('bringing on stage failed', err2s(err));
    });
  }, [preselectedClientIds, block.id, isLive, gameSessionStatus, stageControl]);

  useEffect(() => {
    if (gameSessionStatus !== SpotlightBlockV2GameSessionStatus.END) return;
    const promises = stageMembers.map((m) => {
      return stageControl.leave(m.id);
    });
    Promise.all(promises).catch((err) => {
      log.error('remove from stage failed', err2s(err));
    });
  }, [gameSessionStatus, stageControl, stageMembers]);

  useEffect(() => {
    if (
      gameSessionStatus !== SpotlightBlockV2GameSessionStatus.CELEBRATING ||
      votingStatus !== SpotlightV2VotingStatus.Celebrating
    )
      return;

    const run = async () => {
      await sleep(3000);
      stopVoteCelebrating();
    };
    run();
  }, [gameSessionStatus, stopVoteCelebrating, votingStatus]);

  const createSnapshot = useCreateGameInfoSnapshot();
  const hasPlayedVoiceOver = useRef(false);
  useEffect(() => {
    async function run() {
      if (
        isLive ||
        gameSessionStatus !== SpotlightBlockV2GameSessionStatus.CELEBRATING ||
        hasPlayedVoiceOver.current
      )
        return;

      hasPlayedVoiceOver.current = true;
      try {
        const playbackItem = getCurrentPlaybackItem();
        const voiceOverPlans = playbackItem?.voiceOverPlans ?? [];
        if (voiceOverPlans.length === 0) return;
        const vo = voiceOverPlans[0];
        const reg =
          createSnapshot()?.variableRegistry ?? new VariableRegistry();
        const req = await lvoTTSRequestFromPlan(vo.plan, reg);
        const player = new LVOBroadcastPlayer(req);
        const info = await player.play();
        await info.tracksEnded;
      } finally {
        await ondWaitReadyForSkip();
      }
    }
    run();
  });

  return null;
}
