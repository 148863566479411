import { useEffect, useRef, useState } from 'react';

import {
  type TitleBlockV2,
  type TitleBlockV2GameSessionStatus,
  type TitleCard,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getLogger } from '../../../../logger/logger';
import { sleep } from '../../../../utils/common';
import { Emitter } from '../../../../utils/emitter';
import { TagQuery } from '../../../../utils/TagQuery';
import {
  useCohostClientId,
  useHostClientId,
  useParticipantsGetter,
} from '../../../Player';
import { StageMode, useStageControlAPI } from '../../../Stage';
import { useTeamGetter, useTeamMembersGetter } from '../../../TeamAPI/TeamV1';
import {
  LVOBroadcastPlayer,
  lvoTTSRequestFromPlan,
} from '../../../VoiceOver/LocalizedVoiceOvers';
import { VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import {
  useCreateGameInfoSnapshot,
  useGameSessionActionsSignalManager,
  useGameSessionStatus,
  useGetOndGameCurrentPlaybackItem,
  useOndGameCurrentPlaybackItemId,
  useOndGameState,
  useOndPlaybackVersion,
  useOndWaitMode,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { ondWaitReadyForSkip } from '../../OndPhaseRunner/OndPhaseRunner';
import { OndVersionChecks } from '../../OndVersionChecks';
import {
  usePlaybackInfoCurrentBlock,
  usePlaybackInfoExtra,
} from '../../Playback/PlaybackInfoProvider';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { useStableBlock } from '../Common/hooks';
import { useTitleBlockV2State } from './TitleBlockV2Hooks';
import { TitleV2Utils } from './utils';

const log = getLogger().scoped('title-control');

/**
 * We need to know when wait mode is exited manually, such as during playtesting
 * ("Skip Ahead"), so we can cancel voice overs. An abort controller seems a
 * natural fit, but it is a one-time use instance that is tough to refresh in a
 * react work.
 */
function useWaitModeAbortEmitter() {
  const state = useOndGameState();
  const waitMode = useOndWaitMode();
  const currItemId = usePlaybackInfoCurrentBlock()?.id;
  const prevItemId = useRef<string | undefined>(undefined);
  const [abortEmitter] = useState(() => new Emitter<{ abort: () => void }>());
  useEffect(() => {
    // NOTE: we cannot use `waitMode !== 'wait'` because the waitMode is nullish
    // during a pause -> resume transition: it is reset by the PhaseRunner as
    // part of resuming, after which it is set to valid values. We have to avoid
    // executing the abort emission during this transition.

    if (
      // this happens when the user Skips Ahead
      (waitMode === 'resume' && state === 'running') ||
      // this happens when the user chooses to a different block in the playtest
      // panel
      currItemId !== prevItemId.current
    ) {
      abortEmitter.emit('abort');
    }

    prevItemId.current = currItemId;
  }, [abortEmitter, currItemId, state, waitMode]);
  return abortEmitter;
}

function TeamIntroStageControl(props: { currentCard: TitleCard | null }) {
  const { currentCard } = props;
  const extra = usePlaybackInfoExtra();
  const teamId = TitleV2Utils.CardExtraTeamId(extra, currentCard);
  const getMembers = useTeamMembersGetter();
  const hostClientId = useHostClientId();
  const cohostClientId = useCohostClientId();

  const stageAPI = useStageControlAPI();

  // Remove all members when the block starts, just in case there are leftovers.
  useEffect(() => {
    stageAPI.leaveAll([hostClientId, cohostClientId]).catch();
  }, [cohostClientId, hostClientId, stageAPI]);

  useEffect(() => {
    if (!teamId) return;
    const members = getMembers(teamId);
    if (!members) return;
    members.forEach((member) =>
      stageAPI.join(member.id, StageMode.BLOCK_CONTROLLED)
    );

    return () => {
      members.forEach((member) => stageAPI.leave(member.id));
    };
  }, [getMembers, stageAPI, teamId]);

  const signalMan = useGameSessionActionsSignalManager();
  useEffect(() => {
    return signalMan.connect({
      name: 'reset',
      before: async () => {
        await stageAPI.leaveAll([hostClientId, cohostClientId]);
      },
    });
  }, [cohostClientId, hostClientId, signalMan, stageAPI]);

  return null;
}

function VoiceOverControl(props: {
  block: TitleBlockV2;
  currentCard: TitleCard | null;
}) {
  const { block, currentCard } = props;
  const extra = usePlaybackInfoExtra();
  const teamId = TitleV2Utils.CardExtraTeamId(extra, currentCard);
  const plan = TitleV2Utils.CardExtraVoiceOverPlan(extra, currentCard);
  const delayStartMs = TitleV2Utils.CardExtraVoiceOverDelayStartMs(
    extra,
    currentCard
  );
  const getMembers = useTeamMembersGetter();
  const getTeam = useTeamGetter();
  const getParticipants = useParticipantsGetter();
  const ondTitleBlockDrivesVoiceOvers = OndVersionChecks(
    useOndPlaybackVersion()
  ).ondTitleBlockDrivesVoiceOvers;
  const createGameInfoSnapshot = useCreateGameInfoSnapshot();
  const currentPbItemId = useOndGameCurrentPlaybackItemId();
  const getCurrentPbItem = useGetOndGameCurrentPlaybackItem();

  const emitter = useGamePlayEmitter();
  const abortEmitter = useWaitModeAbortEmitter();
  const waitMode = useOndWaitMode();

  const execTeamIntroPlay = useLiveCallback(async () => {
    if (!plan) return;

    const formatter = new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    });

    let variables =
      createGameInfoSnapshot()?.variableRegistry ?? new VariableRegistry();

    if (teamId) {
      const team = getTeam(teamId);
      const members = getMembers(teamId);
      const participants = getParticipants();

      variables = VariableRegistry.FromRecord({
        teamIntroTeamName: team?.name ?? '',
        teamIntroContestantNames: formatter.format(
          members?.map(
            (m) =>
              participants[m.id]?.firstName ??
              participants[m.id]?.username ??
              ''
          ) ?? []
        ),
      });
    }

    const waitModeAbort = abortEmitter.oncep('abort');

    const req = await lvoTTSRequestFromPlan(plan, variables);
    const vo = new LVOBroadcastPlayer(req);
    const info = await vo.play({ delayStartMs });
    await Promise.race([info.tracksEnded, waitModeAbort]);
    vo.stop();
  });

  const execNormalPlay = useLiveCallback(async () => {
    // three competing timings:
    // - gameplaymedia, which could be empty...
    // - waitModeAbort, manual click
    // - voice over group trackended, which could be empty...

    // we expect `plan` to come from `extra`. but if not, we can try looking for
    // a plan from the playback item directly. this is acceptable for normal
    // title card playback because all the vo plans were created when the
    // playback plan was first created, and thus available.
    //
    // this is unacceptable for team intro title card playback because the team
    // intro card will expand into _many_ cards when the recording is generated
    // (which happens _after_ the playback item is created). some vo plans will
    // exist on the item, but not all (e.g., the cards created in generate
    // recording).
    const currentPbItem = getCurrentPbItem();
    let voPlan = plan;
    if (!voPlan && currentPbItem && currentCard?.id) {
      const plans = new TagQuery(currentPbItem.voiceOverPlans);
      voPlan = plans.selectFirst(['card', currentCard.id])?.plan ?? null;
    }

    // This must be before any `await`s. We must successfully subscribe to the
    // event before the event is emitted. If loading audio takes longer than the
    // length of the gameplaymedia (especially common if the media is an
    // image... duration == 0!), there is a chance that we will miss the event
    // if the subscription happens after starting to load audio! This is brittle
    // but cannot be improved without moving control of playing of the gameplay
    // media into this component.
    const gameplaymediaEnded = currentCard?.media
      ? emitter.oncep('title-card-game-play-media-ended')
      : Promise.resolve();

    let trackEnded = Promise.resolve();

    const variables = createGameInfoSnapshot(currentPbItemId)?.variableRegistry;

    let player;
    if (voPlan && variables) {
      const req = await lvoTTSRequestFromPlan(voPlan, variables);
      if (req) {
        player = new LVOBroadcastPlayer(req);
        const info = await player.play({ delayStartMs });
        trackEnded = info.tracksEnded ?? Promise.resolve();
      }
    }

    const waitModeAbort = abortEmitter.oncep('abort');

    const playFinished = Promise.all([trackEnded, gameplaymediaEnded]);
    await Promise.race([playFinished, waitModeAbort]);
    player?.stop();
  });

  const onCardComplete = useLiveCallback(() => {
    if (block.fields.waitAfterEachCardSec > 0) {
      ondWaitReadyForSkip();
    } else {
      ondWaitEnd();
    }
  });

  const onError = useLiveCallback((e: unknown) => {
    log.error('failed to play voice overs', e);
    // in the event of an error, wait a couple of seconds for pacing.
    return sleep(3000);
  });

  const hasTriggered = useRef(new Set<TitleCard['id']>());
  useEffect(() => {
    if (
      !ondTitleBlockDrivesVoiceOvers ||
      !currentCard ||
      waitMode !== 'wait' ||
      hasTriggered.current.has(currentCard.id)
    )
      return;

    hasTriggered.current.add(currentCard.id);
    if (currentCard.teamIntroEnabled) {
      execTeamIntroPlay().catch(onError).finally(onCardComplete);
    } else {
      execNormalPlay().catch(onError).finally(onCardComplete);
    }
  }, [
    currentCard,
    currentCard?.teamIntroEnabled,
    execNormalPlay,
    execTeamIntroPlay,
    ondTitleBlockDrivesVoiceOvers,
    onCardComplete,
    onError,
    waitMode,
  ]);

  return null;
}

export function TitleBlockV2GameControl(props: { block: TitleBlockV2 }) {
  const gameSessionBlock = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<TitleBlockV2GameSessionStatus>();
  const { currentCard } = useTitleBlockV2State(
    gameSessionBlock,
    gameSessionStatus
  );

  return (
    <>
      <TeamIntroStageControl currentCard={currentCard} />
      <VoiceOverControl block={gameSessionBlock} currentCard={currentCard} />
    </>
  );
}
