import uniq from 'lodash/uniq';
import {
  memo,
  type PointerEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import { usePreviousDistinct, useTimeoutFn } from 'react-use';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import {
  useBotFakeParticipant,
  useBotFakeParticipantFlags,
  useBots,
  useParticipantIsBot,
} from '../../../../components/Bot';
import { type Bot } from '../../../../components/Bot/types';
import {
  useCohostNamedPosition,
  useCohostPositionManager,
} from '../../../../components/Cohost/CohostPositionManagerProvider';
import {
  CrowdFramesAvatar,
  CrowdFramesRenderlessAvatar,
} from '../../../../components/CrowdFrames';
import {
  LayoutAnchor,
  useLayoutAnchorRectValue,
} from '../../../../components/LayoutAnchors/LayoutAnchors';
import { PlaceholderPres } from '../../../../components/Participant';
import { getRandomAnimatedAvatarVariant } from '../../../../components/Participant/avatars';
import {
  useAmICohost,
  useMyTeamId,
  useParticipant,
  useParticipantFlags,
  useParticipantsAsArray,
  useParticipantsFlags,
  useSelectTeamParticipants,
  useSpectatorFlag,
  useTrackAgoraNumericUid,
} from '../../../../components/Player';
import { useRightPanelUIAction } from '../../../../components/RightPanelContext';
import { useIsStreamSessionAlive } from '../../../../components/Session';
import { useSoundEffect } from '../../../../components/SFX';
import {
  useJoinTeam,
  useTeam,
  useTeamColor,
  useTeamWithStaff,
} from '../../../../components/TeamAPI/TeamV1';
import {
  type TownhallMode,
  useSortedSpeakers,
  useTownhallConfig,
  useTownhallOnsCrowdLastSpokenAtMap,
} from '../../../../components/Townhall';
import { useUser, useUserStates } from '../../../../components/UserContext';
import { useMyClientId } from '../../../../components/Venue/VenuePlaygroundProvider';
import {
  useVenueDerivedSettings,
  useVenueId,
} from '../../../../components/Venue/VenueProvider';
import {
  useJoinRTCService,
  usePublishStream,
  useRTCService,
  useTriggerWebRTCJoinFailedModal,
  useTriggerWebRTCUIDBannedModal,
} from '../../../../components/WebRTC';
import { useZoomNumOfParticipants } from '../../../../components/Zoom/ZoomVenueProvider';
import {
  getFeatureQueryParamNumber,
  useFeatureQueryParam,
} from '../../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { type TaskQueue } from '../../../../hooks/useTaskQueue';
import { useIsUnloading } from '../../../../hooks/useUnload';
import { useVenueMode } from '../../../../hooks/useVenueMode';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { safeWindowReload } from '../../../../logger/logger';
import {
  type IMediaDeviceRTCService,
  WebRTCUtils,
} from '../../../../services/webrtc';
import {
  isStaff,
  type Participant,
  type TeamId,
  VenueMode,
} from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { playWithCatch } from '../../../../utils/playWithCatch';
import { rsCounter, rsIncrement } from '../../../../utils/rstats.client';
import { runAfterFramePaint } from '../../../../utils/runAfterFramePaint';
import { useSubscribeAudienceRTCEvents } from '../Common/useSubscribeAudienceRTCEvents';
import { LocalStreamView } from '../LocalStreamView';
import { PlayerInfoOverlay } from '../PlayerInfoOverlay';
import { useRemoteStreamStateAPI } from '../RemoteStreamStateProvider';
import { RemoteStreamView } from '../RemoteStreamView';
import { RetryJoinTeam } from '../RetryJoinTeam';
import {
  RemoteStillPlaying,
  RemoteStreamSubscribeProvider,
} from './RemoteStreamSubscribeProvider';

function makeStreamContainerClass(): [string] {
  const classNames = [
    'flex-shrink-0 filter drop-shadow-lp-team-stream',
    'border-transparent border-[3px]',
    'group-1-hover:border-primary m-px',
  ];

  return [classNames.join(' ')];
}

function useMembers(mode?: TownhallMode) {
  rsIncrement('eval-townhall-teamview-useMembers-c');
  const myClientId = useMyClientId();
  const myTeamId = useMyTeamId();
  const onsCrowdLastSpokenAtMap = useTownhallOnsCrowdLastSpokenAtMap();
  const crowdMembers = useParticipantsAsArray({
    filters: [
      'host:false',
      'cohost:false',
      'status:connected',
      'team:true',
      'staff:false',
    ],
  });
  const { numOfcrowdSeats } = useTownhallConfig();
  const crowdSortedMembers = useMemo(() => {
    rsIncrement('cache-miss-townhall-teamview-useMembers-c');
    const filtered = crowdMembers.filter((m) => m.clientId !== myClientId);
    // my team is always placed at the beginning of the stage
    const fakeMyTeamId = '';
    const cmpTeam = (a: Participant, b: Participant): number => {
      if (!a.teamId || !b.teamId) return 1;
      const t1 = a.teamId === myTeamId ? fakeMyTeamId : a.teamId;
      const t2 = b.teamId === myTeamId ? fakeMyTeamId : b.teamId;
      return t1.localeCompare(t2);
    };
    if (filtered.length <= numOfcrowdSeats) {
      // my team first, followed by other teams
      return filtered.sort((a, b): number => {
        const diff = cmpTeam(a, b);
        return diff === 0 ? a.joinedAt - b.joinedAt : diff;
      });
    } else {
      return filtered.sort((a, b): number => {
        // my team first
        const aMyTeam = a.teamId === myTeamId;
        const bMyTeam = b.teamId === myTeamId;
        if (aMyTeam && bMyTeam) {
          return a.joinedAt - b.joinedAt;
        } else if (aMyTeam) {
          return -1;
        } else if (bMyTeam) {
          return 1;
        }

        const aLastSpokenAt = onsCrowdLastSpokenAtMap?.[a.clientId] ?? 0;
        const bLastSpokenAt = onsCrowdLastSpokenAtMap?.[b.clientId] ?? 0;

        if (aLastSpokenAt === 0 && bLastSpokenAt === 0) {
          // if none of them spoke, sort by team. So before anyone talks,
          // member are still sorted by teams.
          const r = cmpTeam(a, b);
          return r === 0 ? a.joinedAt - b.joinedAt : r;
        } else {
          // if any of them spoke, sort by who spoken recently
          const diff = bLastSpokenAt - aLastSpokenAt;
          return diff === 0 ? a.joinedAt - b.joinedAt : diff;
        }
      });
    }
  }, [
    crowdMembers,
    numOfcrowdSeats,
    myClientId,
    myTeamId,
    onsCrowdLastSpokenAtMap,
  ]);

  // TODO: maybe also filter the members who are not in team mode
  const rawMyTeamMembers = useSelectTeamParticipants(myTeamId ?? '');
  const myTeamMembers = rawMyTeamMembers.filter(
    (m) => m.clientId !== myClientId
  );
  const user = useUser();

  // there is a moment during the team randomization that the mode is not
  // available, we just assume the user is in the group to avoid the flicker.
  if (!mode) {
    return {
      members: crowdSortedMembers,
      remoteUsersCount: crowdSortedMembers.length,
      meInTheGroup: true,
    };
  }

  const remoteUsers = mode === 'team' ? myTeamMembers : crowdSortedMembers;
  const all = mode === 'team' ? rawMyTeamMembers : crowdMembers;

  return {
    members: remoteUsers,
    remoteUsersCount: crowdSortedMembers.length,
    meInTheGroup: isStaff(user)
      ? true
      : !!all.find((m) => m.clientId === myClientId),
  };
}

function MoreUsers(props: {
  hiddenMembers: Participant[];
  className: string;
  widthPx: number;
  heightPx: number;
  borderRadiusPx: number;
}): JSX.Element | null {
  const { hiddenMembers, className, widthPx, heightPx, borderRadiusPx } = props;
  if (hiddenMembers.length === 0) return null;
  const members = hiddenMembers.slice(0, 3);
  const colOffset = (i: number): number => {
    if (members.length === 1) {
      return 4 + 16;
    } else if (members.length === 2) {
      return 4 + i * 32;
    } else {
      return 4 + i * 16;
    }
  };

  return (
    <div
      className={`${className} overflow-hidden`}
      style={{
        width: widthPx,
        height: heightPx,
        borderRadius: borderRadiusPx,
      }}
    >
      <div className='flex flex-col items-center justify-center bg-black bg-opacity-60 w-full h-full'>
        <div className='w-full h-1/2 flex relative items-center justify-center'>
          {members.map((m, i) => (
            <div
              key={m.clientId}
              className='absolute top-0'
              style={{
                left: `${colOffset(i)}px`,
              }}
            >
              <div className='w-8 h-8 relative'>
                <CrowdFramesAvatar
                  participant={m}
                  profileIndex={ProfileIndex.wh36x36fps4}
                  enablePointerEvents={false}
                />
              </div>
            </div>
          ))}
        </div>
        <div className='text-white text-3xs font-bold'>
          {hiddenMembers.length} more
        </div>
      </div>
    </div>
  );
}

function PlayTeamSwitchBackSFX(props: {
  myTownhallMode?: TownhallMode;
  teamId: TeamId;
}): JSX.Element | null {
  const { myTownhallMode, teamId } = props;
  const team = useTeam(teamId);
  const currMode = team?.townhallMode;
  const prevMode = usePreviousDistinct(currMode);
  const { play } = useSoundEffect('townhallTeamSwitchBackToCrowd');

  useEffect(() => {
    if (myTownhallMode !== 'crowd') return;
    if (prevMode === 'team' && currMode === 'crowd') {
      play();
    }
  }, [currMode, myTownhallMode, play, prevMode]);
  return null;
}

function BotView(props: {
  bot: Bot;
  className: string;
  widthPx: number;
  heightPx: number;
  borderRadiusPx: number;
}): JSX.Element {
  const { bot, className, widthPx, heightPx, borderRadiusPx } = props;
  const participant = useBotFakeParticipant(bot);
  const flags = useBotFakeParticipantFlags();
  const teamColor = useTeamColor(useMyTeamId());
  return (
    <div className={`relative group-1`}>
      <div
        className={`${className} overflow-hidden border-[3px]`}
        style={{
          borderColor: teamColor,
          width: widthPx,
          height: heightPx,
          borderRadius: borderRadiusPx,
        }}
      >
        {bot.video && (
          <video
            className='w-full h-full absolute z-10 top-0 left-0'
            src={bot.video}
            muted
            loop
            autoPlay
          />
        )}
        <PlaceholderPres
          participantUsername={participant?.username}
          hasCamera={flags?.hasCamera}
          hasMicrophone={flags?.hasMicrophone}
          showLiteMode={false}
          noIndicators={false}
        />

        <PlayerInfoOverlay
          clientId={participant.clientId}
          teamId={participant.teamId}
        />
        <div className='w-full h-full bg-black bg-opacity-60'></div>
      </div>
    </div>
  );
}

const TownhallRemoteStreamView = memo(function TownhallRemoteStreamView(
  props: Parameters<typeof RemoteStreamView>[0] & {
    hide: boolean;
  }
): JSX.Element {
  const isSessionAlive = useIsStreamSessionAlive();
  const { targetMemberId, currentMemberId, hide } = props;
  // TODO(drew): useTeam is expensive to compute for each stream view!
  const myTeam = useTeam(useParticipant(currentMemberId)?.teamId);
  const targetTeam = useTeam(useParticipant(targetMemberId)?.teamId);
  const config = useTownhallConfig();

  const showStillPlayingAnimation =
    isSessionAlive &&
    targetTeam?.townhallMode === 'team' &&
    myTeam?.townhallMode === 'crowd' &&
    config.forceMode !== 'team';

  return (
    <>
      <CrowdFramesRenderlessAvatar
        clientId={props.targetMemberId}
        profileIndex={ProfileIndex.wh100x100fps8}
      />
      {showStillPlayingAnimation ? (
        <RemoteStillPlaying
          className={`${hide ? 'hidden' : 'flex'}`}
          targetClientId={targetMemberId}
          targetTeamId={targetTeam.id}
          widthPx={props.widthPx}
          heightPx={props.heightPx}
        />
      ) : (
        <RemoteStreamView
          {...props}
          className={`${props.className} ${hide ? 'hidden' : 'flex'}`}
        />
      )}
    </>
  );
});

function TownhallMemberPlaceholderContainer(props: {
  className: string;
  widthPx: number;
  heightPx: number;
  borderRadiusPx: number;
  children?: React.ReactNode;
  last?: boolean;
}): JSX.Element {
  // the placeholder needs custom border style
  const className = useMemo(
    () =>
      props.className
        .split(' ')
        .filter((c) => !c.includes('border'))
        .join(' '),
    [props.className]
  );
  return (
    <div
      className={`relative group-1 ${
        props.last ? 'townhall-member-placeholder-mask-image' : ''
      }`}
    >
      <div
        className={`${className} overflow-hidden border-[3px] border-dashed border-secondary`}
        style={{
          width: props.widthPx,
          height: props.heightPx,
          borderRadius: props.borderRadiusPx,
        }}
      >
        <div className='relative w-full h-full bg-black bg-opacity-60 overflow-hidden'>
          {props.children}
        </div>
      </div>
    </div>
  );
}

function TownhallMemberPlaceholder(props: {
  className: string;
  widthPx: number;
  heightPx: number;
  borderRadiusPx: number;
  last?: boolean;
  withAvatar?: string;
}): JSX.Element {
  if (props.withAvatar) {
    return (
      <TownhallMemberPlaceholderWithAvatar
        className={props.className}
        last={props.last}
        src={props.withAvatar}
        widthPx={props.widthPx}
        heightPx={props.heightPx}
        borderRadiusPx={props.borderRadiusPx}
      />
    );
  } else {
    return (
      <TownhallMemberPlaceholderContainer
        className={props.className}
        last={props.last}
        widthPx={props.widthPx}
        heightPx={props.heightPx}
        borderRadiusPx={props.borderRadiusPx}
      />
    );
  }
}

function TownhallMemberPlaceholderWithAvatar(props: {
  src: string;
  className: string;
  widthPx: number;
  heightPx: number;
  borderRadiusPx: number;
  last?: boolean;
}): JSX.Element {
  const videoRef = useRef<HTMLVideoElement>(null);
  // jitter the start time so they don't feel too synchronized.
  useTimeoutFn(() => playWithCatch(videoRef.current), Math.random() * 2500);
  return (
    <TownhallMemberPlaceholderContainer
      className={props.className}
      last={props.last}
      widthPx={props.widthPx}
      heightPx={props.heightPx}
      borderRadiusPx={props.borderRadiusPx}
    >
      <div className='absolute inset-0 bg-black bg-opacity-40' />
      <video
        ref={videoRef}
        src={props.src}
        autoPlay={false}
        loop
        muted
        className='w-full h-full'
        controls={false}
        disableRemotePlayback
      />
      <div className='absolute bottom-1 left-0 right-0 flex items-center justify-center'>
        <div className='text-3xs xl:text-2xs lp-sm:text-xs text-white font-bold text-center'>
          Invite a Guest
        </div>
      </div>
    </TownhallMemberPlaceholderContainer>
  );
}

/**
 * This measures the entire safe zone of the top layout, where townhall and
 * cohost can be without overlapping critical UI elements (or be overlapped).
 * The padding that pushes the safe zone in is defined in PinnedTopLayout /
 * MiddlePanel.
 */
function TopSpacingLayoutAnchor() {
  return (
    <LayoutAnchor
      id='lobby-top-spacing-anchor'
      className='w-full h-full absolute z-0'
    />
  );
}

/**
 * This wraps the actual sized townhall grid. Therefore, the X component
 * (relative to the window) defines the safe area where the cohost can be.
 */
function CohostSafeZoneLayoutAnchor() {
  return (
    <LayoutAnchor
      id='lobby-cohost-spacing-anchor'
      className='absolute inset-0'
    />
  );
}

function DefaultTeamView(props: {
  teamId: TeamId;
  rtc: IMediaDeviceRTCService;
  joined: boolean;
  maximumColsTheshold?: number;
}): JSX.Element | null {
  const { teamId, rtc, joined } = props;
  const me = useMyInstance();
  const flags = useParticipantFlags(me?.clientId);
  const team = useTeamWithStaff(teamId);
  const config = useTownhallConfig();
  const teamTownhallMode =
    team?.isStaffTeam && !flags?.spectator && !team?.isCohostTeam
      ? 'team'
      : team?.townhallMode;
  const showLocalStream = !flags?.spectator && !me?.cohost;
  const { members, remoteUsersCount, meInTheGroup } =
    useMembers(teamTownhallMode);
  const membersCount = members.length + 1;
  const pFlags = useParticipantsFlags();
  const { frontStageTeamIds, frontStageViewableMemberIds } = useMemo(() => {
    return {
      frontStageTeamIds: uniq(
        members
          .map((m) => m.teamId)
          .filter((id): id is string => !!id && id !== teamId)
      ),
      frontStageViewableMemberIds: members
        .filter(
          (m) => pFlags[m.clientId]?.video && pFlags[m.clientId]?.hasCamera
        )
        .map((m) => m.clientId),
    };
  }, [members, teamId, pFlags]);
  const bots = useBots();
  const venueMode = useVenueMode();
  const venueCapacityCheckEnabled = useFeatureQueryParam(
    'venue-capacity-check'
  );
  const derivedVenueSeatCap = useVenueDerivedSettings()?.seatCap;
  const venueSeatCap = venueCapacityCheckEnabled
    ? derivedVenueSeatCap
    : undefined;
  const amICohost = useAmICohost();
  const numOfPlaceholders = useMemo(() => {
    if (amICohost && membersCount === 1 && venueMode === VenueMode.Game) {
      // show the placeholders for a cohost playing by themselves.
      return 4;
    }

    if (
      venueMode === VenueMode.Game ||
      config.mode === 'team' ||
      membersCount > 1 ||
      bots.length > 0
    ) {
      return 0;
    }

    if (venueSeatCap) {
      // -1 since someone is already here.
      return Math.min(venueSeatCap - 1, 4);
    } else {
      return 4;
    }
  }, [
    amICohost,
    bots.length,
    config.mode,
    membersCount,
    venueMode,
    venueSeatCap,
  ]);

  const seatsCount = membersCount + numOfPlaceholders + bots.length;

  const [className] = useMemo(() => makeStreamContainerClass(), []);

  const maximumColsTheshold = props.maximumColsTheshold ?? 8;

  const cols = useMemo(
    () =>
      seatsCount <= maximumColsTheshold
        ? seatsCount
        : Math.round(Math.min(seatsCount, config.numOfcrowdSeats) / 2),
    [config.numOfcrowdSeats, maximumColsTheshold, seatsCount]
  );
  const rows = seatsCount > maximumColsTheshold ? 2 : 1;
  const candidateSpeakers = useSortedSpeakers(frontStageViewableMemberIds);
  const retryCount = useRef(0);
  const isUnloading = useIsUnloading();
  const joinTeam = useJoinTeam();
  const copman = useCohostPositionManager();
  const cohostPosition = useCohostNamedPosition();

  const [whPx, setWhPx] = useState(() => ({
    streamView: 0,
    cohost: 0,
    cohostLeft: 0,
    cohostTop: 0,
    moreUsers: 0,
    streamViewBorderRadius: 0,
    cohostBorderRadius: 0,
  }));

  const seed = useState(() => Date.now());
  const placeholders = useMemo(() => {
    // we want to occupy the space for the cohost panel, but not show the placeholders.
    const hidePlaceholders = amICohost && venueMode === VenueMode.Game;
    return (
      <>
        {[...Array(numOfPlaceholders)].map((_, i) => (
          <TownhallMemberPlaceholder
            key={i}
            className={`${className} ${hidePlaceholders ? 'invisible' : ''}`}
            last={i === numOfPlaceholders - 1}
            withAvatar={
              !!venueSeatCap
                ? getRandomAnimatedAvatarVariant(i, `${seed}-${i}`)
                : undefined
            }
            widthPx={whPx.streamView}
            heightPx={whPx.streamView}
            borderRadiusPx={whPx.streamViewBorderRadius}
          />
        ))}
      </>
    );
  }, [
    amICohost,
    className,
    numOfPlaceholders,
    seed,
    venueMode,
    venueSeatCap,
    whPx.streamView,
    whPx.streamViewBorderRadius,
  ]);

  const windims = useWindowDimensions();
  const safezoneWidthPx = useLayoutAnchorRectValue(
    'lobby-top-spacing-anchor',
    'width'
  );

  useLayoutEffect(() => {
    // flushSync cannot be called within a lifecycle (hooK) method, so get us
    // out of it! We need to use flushSync so we can update the townhall layout
    // and immediately read back its actual dimensions.
    queueMicrotask(() => {
      // First, compute and update the streamView sizes in the DOM.
      {
        if (!safezoneWidthPx) return;

        // This is an integer, but we need a factor. Divide by 100 to convert.
        const townhallHeightPct =
          getFeatureQueryParamNumber('townhall-height-pct') / 100;

        // Allocate a specific percentage of the entire window for the stream view.
        // Note that the number of columns factors in, too, so the safezoneWidthPx
        // has a larger effect on the final size.
        const availableHeight = windims.height * townhallHeightPct;

        const streamSize = Math.floor(
          Math.min(safezoneWidthPx / cols, availableHeight / rows)
        );

        // min-width! Otherwise it gets too small and unreadable.
        const moreUsersMinWidthPx = 76;

        flushSync(() => {
          setWhPx((s) => ({
            ...s,
            streamView: streamSize,
            moreUsers: Math.max(streamSize, moreUsersMinWidthPx),
            streamViewBorderRadius: 0.2 * streamSize,
          }));
        });
      }

      // Next, DOM should now be updated, layout the cohost!
      copman.update(windims);
    });

    // we need to keep the _cohostPosition_ as a dependency, so we can update
    // the cohost's actual position when it changes.
  }, [cohostPosition, cols, copman, rows, safezoneWidthPx, windims]);

  if (!me || !flags) return null;

  const onRetry = async () => {
    if (retryCount.current >= 2) {
      safeWindowReload();
    } else {
      retryCount.current++;
      await joinTeam({
        teamId,
        memberId: me.clientId,
        debug: 'join-team-fix',
        force: true,
      });
    }
  };

  if (!me.cohost && !meInTheGroup && !isUnloading) {
    return (
      <div className='w-full relative'>
        <TopSpacingLayoutAnchor />
        <RetryJoinTeam onRetry={onRetry} />
      </div>
    );
  }

  return (
    <div className='w-full flex justify-center items-center mt-2'>
      <div
        className={`
        grid place-items-center place-content-center gap-0 relative pointer-events-auto transition-none
      `}
        style={{
          gridTemplateColumns: `repeat(${cols}, 1fr)`,
        }}
      >
        <CohostSafeZoneLayoutAnchor />

        <BotJoinEnder members={members} />
        {showLocalStream && (
          <LocalStreamView
            className={className}
            id={me.clientId}
            rtcService={rtc}
            clientId={me.clientId}
            isRTCServiceSynced
            teamMemberCount={seatsCount}
            miniMode={false}
            widthPx={whPx.streamView}
            heightPx={whPx.streamView}
            borderRadiusPx={whPx.streamViewBorderRadius}
          />
        )}
        {placeholders}
        {members.map((m, idx) => {
          return (
            <RemoteStreamSubscribeProvider
              key={m.clientId}
              rtc={rtc}
              joined={joined}
              clientId={m.clientId}
              mode={teamTownhallMode}
              remoteSeatsCount={seatsCount - 1}
              remoteSeatIndex={idx}
              remoteUsersCount={remoteUsersCount}
              candidateSpeakers={candidateSpeakers}
            >
              {(strategy) => (
                <TownhallRemoteStreamView
                  id={m.clientId}
                  className={`${className}`}
                  // Your local stream is in the area, so make room for it (-1).
                  // The Cohost's view does not show your local stream in the
                  // townhall area (it would be a duplicate), so do not make
                  // room for it!
                  hide={idx >= config.numOfcrowdSeats - (amICohost ? 0 : 1)}
                  targetMemberId={m.clientId}
                  currentMemberId={me.clientId}
                  rtcService={rtc}
                  isRTCServiceSynced
                  teamMemberCount={seatsCount}
                  miniMode={false}
                  strategy={strategy}
                  widthPx={whPx.streamView}
                  heightPx={whPx.streamView}
                  borderRadiusPx={whPx.streamViewBorderRadius}
                />
              )}
            </RemoteStreamSubscribeProvider>
          );
        })}

        {bots.map((bot) => (
          <BotView
            key={bot.id}
            className={className}
            bot={bot}
            widthPx={whPx.streamView}
            heightPx={whPx.streamView}
            borderRadiusPx={whPx.streamViewBorderRadius}
          />
        ))}
        {frontStageTeamIds.map((teamId) => (
          <PlayTeamSwitchBackSFX
            key={teamId}
            myTownhallMode={team?.townhallMode}
            teamId={teamId}
          />
        ))}
        {team?.townhallMode === 'crowd' && (
          <MoreUsers
            hiddenMembers={members.slice(config.numOfcrowdSeats - 1)}
            className={className + ' absolute left-full bottom-0'}
            widthPx={whPx.moreUsers}
            heightPx={whPx.moreUsers}
            borderRadiusPx={whPx.streamViewBorderRadius}
          />
        )}
      </div>

      <TopSpacingLayoutAnchor />
    </div>
  );
}

function BotJoinEnder(props: { members: Participant[] }) {
  const [known] = useState(() => new Set<string>());
  const isBot = useParticipantIsBot();
  useLayoutEffect(() => {
    return runAfterFramePaint(() => {
      for (const p of props.members) {
        if (!known.has(p.clientId) && isBot(p)) {
          rsCounter(`bot-join-${p.clientId}-ms`)?.end();
        }
        known.add(p.clientId);
      }
    });
  });
  return null;
}

function DefaultTeamViewContainer(props: {
  teamId: TeamId;
  rtc: IMediaDeviceRTCService;
  joinTaskQueue: TaskQueue;
  maximumColsTheshold?: number;
}): JSX.Element {
  const { teamId, rtc, joinTaskQueue } = props;
  const venueId = useVenueId();
  const trackAgoraNumericUid = useTrackAgoraNumericUid();
  const myClientId = useMyClientId();
  const joined = useJoinRTCService(
    rtc,
    WebRTCUtils.ChannelFor('teamV2', venueId),
    'host',
    joinTaskQueue,
    {
      handleFailure: useTriggerWebRTCJoinFailedModal(),
      onJoined: useLiveCallback(async (joinStatus) => {
        rtc.log.info('onJoined Callback', joinStatus);
        if (!joinStatus?.numericUid) return;
        try {
          await trackAgoraNumericUid(
            myClientId,
            'team',
            joinStatus.numericUid,
            Date.now()
          );
        } catch (error) {
          rtc.log.error('Failed to track numeric uid', error);
        }
      }),
    }
  );

  // NOTE(drew): We can remove this once we discover why Agora is banning UIDs
  // on reconnect. 2024-09-18.
  const handleUIDBanned = useTriggerWebRTCUIDBannedModal();
  useEffect(() => {
    const aborter = new AbortController();
    rtc.on('connection-state-disconnected-uid-banned', handleUIDBanned, {
      signal: aborter.signal,
    });
    return () => {
      aborter.abort();
    };
  }, [handleUIDBanned, rtc]);

  const team = useTeamWithStaff(teamId);
  const { enqueueOp } = usePublishStream(rtc);
  const [spectatorFlag] = useSpectatorFlag();
  const isCohost = useAmICohost();
  useSubscribeAudienceRTCEvents(rtc);
  const api = useRemoteStreamStateAPI();

  const shouldGenerallyPublish = team?.isStaffTeam
    ? joined && !spectatorFlag && !isCohost
    : joined;

  // NOTE: Publishing must be:
  // 1. Reactive to audio/video flags
  // 2. Independent of media type

  // This ensures:
  // - Proper handling of stream initialization: the tracks/devices initialize
  //   at different speeds
  // - Correct publish/unpublish behavior for individual tracks: for example,
  //   user could mute only video but not mic
  // - Compatibility with RTCService's publishing conditions: RTCService will
  //   not publish a local track if audio/video is false (e.g.
  //   localUser.hasAudio)

  const { audio, video } = useUserStates();

  useEffect(() => {
    if (audio && shouldGenerallyPublish) {
      enqueueOp((rtc) => rtc.publishAudio());
    }

    return () => {
      enqueueOp((rtc) => rtc.unpublishAudio());
    };
  }, [audio, enqueueOp, rtc, shouldGenerallyPublish]);

  useEffect(() => {
    if (video && shouldGenerallyPublish) {
      enqueueOp((rtc) => rtc.publishVideo());
    }

    return () => {
      enqueueOp((rtc) => rtc.unpublishVideo());
    };
  }, [enqueueOp, rtc, shouldGenerallyPublish, video]);

  useEffect(() => {
    if (!joined) return;
    return () => {
      rtc.stopAll();
      api.clearStreamState();
    };
  }, [api, joined, rtc]);

  return (
    <DefaultTeamView
      rtc={rtc}
      joined={joined}
      teamId={teamId}
      maximumColsTheshold={props.maximumColsTheshold}
    />
  );
}

function MinifiedMemberView(props: { participant: Participant }) {
  const { participant } = props;
  const nameRef = useRef<HTMLDivElement>(null);

  const handlePointerEnter = (e: PointerEvent<HTMLDivElement>) => {
    const el = nameRef.current;
    if (!el) return;
    const parent = e.currentTarget;
    el.style.display = 'block';
    const r1 = parent.getBoundingClientRect();
    const r2 = el.getBoundingClientRect();
    const offset = (r1.width - r2.width) / 2;
    el.style.marginLeft = `${offset}px`;
  };

  const handlePointerLeave = () => {
    const el = nameRef.current;
    if (!el) return;
    el.style.display = 'none';
  };

  return (
    <div
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      <div className='w-10 h-10 relative flex-shrink-0'>
        <CrowdFramesAvatar
          participant={participant}
          profileIndex={ProfileIndex.wh36x36fps4}
          enablePointerEvents={false}
        />
      </div>
      <div
        ref={nameRef}
        className='absolute z-20 text-3xs text-white bg-black px-2 py-1 rounded mt-0.5'
        style={{ display: 'none' }}
      >
        {participant.firstName || participant.username}
      </div>
    </div>
  );
}

function MinifiedTeamView(props: { teamId: TeamId }) {
  const { teamId } = props;
  const me = useMyInstance();
  const flags = useParticipantFlags(me?.clientId);
  const team = useTeamWithStaff(teamId);
  const teamTownhallMode =
    team?.isStaffTeam && !flags?.spectator ? 'team' : team?.townhallMode;
  const { members } = useMembers(teamTownhallMode);
  const membersCount = members.length + 1;
  const [hasMore, setHasMore] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handlePanelUIAction = useRightPanelUIAction();
  const zoomNumOfParticipants = useZoomNumOfParticipants();
  const isSessionAlive = useIsStreamSessionAlive();
  const showJoinState = !isSessionAlive && zoomNumOfParticipants > 0;

  useEffect(() => {
    if (!ref.current) return;
    setHasMore(ref.current.scrollHeight > ref.current.clientHeight);
  }, [membersCount]);

  if (!me) return null;

  return (
    <div className='w-full h-30 xl:h-40 relative'>
      <TopSpacingLayoutAnchor />
      <div className='w-full h-21 relative pointer-events-auto'>
        <div
          ref={ref}
          className='w-full h-full flex gap-0.5 flex-wrap justify-center overflow-hidden'
        >
          <MinifiedMemberView participant={me} />
          {members.map((m) => (
            <MinifiedMemberView key={m.clientId} participant={m} />
          ))}
          {!hasMore && showJoinState && (
            <div
              className='h-10 flex items-center justify-center text-white 
            text-3xs font-medium filter drop-shadow-lp-sm ml-1'
            >
              {membersCount}/{zoomNumOfParticipants} Joined
            </div>
          )}
        </div>
        {hasMore && (
          <div
            className='flex flex-col items-center absolute transform-gpu 
          top-1/2 -translate-y-1/2 right-0 translate-x-full text-white 
            text-3xs font-medium filter drop-shadow-lp-sm'
          >
            {showJoinState && (
              <div>
                {membersCount}/{zoomNumOfParticipants} Joined
              </div>
            )}
            <button
              type='button'
              onClick={() => handlePanelUIAction({ input: 'click-people' })}
            >
              See all
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export function TownhallTeamView(props: {
  taskQueue: TaskQueue;
  teamView: 'default' | 'minified' | 'disabled';
  maximumColsTheshold?: number;
}): JSX.Element | null {
  const me = useMyInstance();
  const teamId = me?.teamId;
  const rtc = useRTCService('audienceV2');

  if (!teamId || !rtc) return null;

  switch (props.teamView) {
    case 'disabled':
      return (
        <div className='w-full h-30 xl:h-40 relative pointer-events-none'>
          <TopSpacingLayoutAnchor />
        </div>
      );
    case 'minified':
      return <MinifiedTeamView teamId={teamId} />;
    case 'default':
      return (
        <DefaultTeamViewContainer
          teamId={teamId}
          rtc={rtc}
          joinTaskQueue={props.taskQueue}
          maximumColsTheshold={props.maximumColsTheshold}
        />
      );
    default:
      assertExhaustive(props.teamView);
      return null;
  }
}
